import React, { useCallback, useContext, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase, { firebaseAuth, uiConfig } from "../../config/constants";
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "../../components/auth/AuthProvider";

export default function LoginPage() {
  const { currentUser, profiles } = useContext(AuthContext);

  if (
    currentUser &&
    Object.keys(profiles).length > 0 &&
    !profiles.onboardingCompleted &&
    profiles.hasOwnProperty("onboardingCompleted")
  ) {
    return <Redirect to="/onboarding" />;
  }
  if (currentUser && !(Object.keys(profiles).length > 0)) {
    console.log("Profiles not loaded");
    return <div></div>;
  }

  if (currentUser && Object.keys(profiles).length > 0) {
    console.log("login redirect");
    return <Redirect to="/edit" />;
  }

  return (
    <div style={{ marginTop: "15%" }} class="valign-wrapper row login-box">
      <div
        style={{ borderRadius: 8, backgroundColor: "#426E93" }}
        class="col card hoverable s10 pull-s1 m6 pull-m3 l4 pull-l4"
      >
        <form>
          <div class="card-content">
            <center>
              <img
                style={{ width: 70, marginLeft: -5 }}
                src={require("../../images/PushPullLogo.png")}
              />
            </center>
            <center>
              <span
                style={{ fontFamily: "Raleway", marginTop: 20, color: "white" }}
                class="card-title"
              >
                Create your actionable, empathetic business card!
              </span>
            </center>

            <div class="row">
              <div class="input-field col s12">
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebaseAuth()}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Emoji } from "emoji-mart";
import { AuthContext } from "../auth/AuthProvider";
import firebase from "../../config/constants";
import { UserContext } from "../../pages/Profile/HomePage";
import { validHandleSchema } from "../../schemas/users";

import "./Modal.css";

export default function ProfileModal(props) {
  const [showModal, setShowModal] = useState(false);
  const [pickerState, setPickerState] = useState(false);
  const [handleError, setHandleError] = useState("");
  const { profiles } = useContext(AuthContext);
  const key = profiles.document_key;

  let { editMode } = useContext(UserContext);

  const {
    userHandle,
    headline,
    headerIntro,
    location,
    occupation,
    setHandle,
    emoji,
    linkedin,
    twitter,
    instagram,
    setLinkedin,
    setTwitter,
    setInstagram,
    setHeadline,
    setHeaderIntro,
    setOccupation,
    setLocation,
    setEmoji
  } = props;
  /**
   * If tapping "Enter", submit form and continue to the next page of onboarding.
   * Function is only needed because emoji-mart widget overtakes the Enter key press.
   **/
  const checkAndHandleEnterKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e);
    }
  };
  const onEmojiClick = emojiObject => {
    setEmoji(emojiObject);

    setPickerState(false);
  };
  const formatLinkedInUrl = () => {
    console.log("hello i am called!")
    let userInput = linkedin;
    if (userInput) {
      if (userInput.startsWith("www")) {
        setLinkedin("http://" + userInput)
      } else if (userInput.startsWith("http")) {
        //do nothing
      } else if (userInput.startsWith("linkedin")) {
        setLinkedin("http://www." + userInput)
      }
      else {
        //must be just their handle
        setLinkedin("http://www.linkedin.com/in/" + userInput)
      }
    }
  }
  const formatTwitterHandle = () => {
    console.log("hi im formatting the handle")
    let userInput = twitter;
    if (userInput) {
      if (userInput.startsWith("www")) {
        setTwitter("http://" + userInput)
      } else if (userInput.startsWith("http")) {
        //do nothing
      } else if (userInput.startsWith("@")) {
        userInput = userInput.substring(1)
        console.log("here's the userInput", userInput)
        setTwitter("http://www.twitter.com/" + userInput)
      } else {
        //must be just their handle
        setTwitter("http://www.twitter.com/" + userInput)
      }
    }
  }
  const formatInstagramHandle = () => {
    let userInput = instagram;
    if (userInput) {
      if (userInput.startsWith("www")) {
        setInstagram("http://" + userInput)
      } else if (userInput.startsWith("http")) {
        //do nothing
      } else if (userInput.startsWith("@")) {
        userInput = userInput.substring(1)
        setInstagram("http://www.instagram.com/" + userInput)
      } else {
        //must be just their handle
        setInstagram("http://www.instagram.com/" + userInput)
      }
    }
  }
  const onSubmit = async e => {
    e.preventDefault();

    try {
      const isValid = await validHandleSchema.validate(
        {
          userHandle: userHandle
        },
        {
          abortEarly: false
        }
      );
      console.log("isvalid", isValid);
      if (!isValid) {
        return;
      }
    } catch (err) {
      console.log(err.errors[0]);
      setHandleError(err.errors[0]);
      return;
    }
    console.log("userhandle", userHandle);
    console.log("headline", headline);
    console.log("headerIntro", headerIntro);
    console.log("location", location);
    console.log("occupation", occupation);
    console.log("key", key);
    console.log("profile", profiles);
    console.log("updated handle: ", userHandle);

    const ref = await firebase
      .firestore()
      .collection("userHandles")
      .doc(userHandle);

    ref.get().then(async doc => {
      let doc_id = doc.id;
      if (doc.exists) {
        let handleOwner = await doc.data();
        formatLinkedInUrl();
        formatTwitterHandle();
        formatInstagramHandle();

        if (handleOwner.user !== profiles.document_key) {
          setHandleError("User handle already exists.");
          throw "err";
        } else {
          console.log("nice didn't change userhandle dummy");

          firebase
            .firestore()
            .collection("profiles")
            .doc(key)
            .update({
              userHandle: userHandle.toLowerCase() || "",
              headline: headline || "",
              headerIntro: headerIntro || "",
              location: location || "",
              occupation: occupation || "",
              emoji: emoji || "grinning",
              linkedin: linkedin || "",
              twitter: twitter || "",
              instagram: instagram || "",
            });
        }
      } else {
        //create new document for the userHandles collection, then update profiles collection
        firebase
          .firestore()
          .collection("userHandles")
          .doc(userHandle)
          .set({
            displayName: profiles.displayName,
            email: profiles.email,
            userHandle: userHandle,
            user: profiles.document_key,
            createdDate: new Date()
          })
          .then(result => {
            firebase
              .firestore()
              .collection("profiles")
              .doc(profiles.document_key)
              .get()
              .then(doc => {
                console.log("check");
                if (doc.data().userHandle === userHandle) {
                  // console.log("already have htis handle");
                  return;
                } else {
                  console.log("deleting", doc.data().userHandle);
                  firebase
                    .firestore()
                    .collection("userHandles")
                    .doc(doc.data().userHandle)
                    .delete()
                    .then(result => {
                      firebase
                        .firestore()
                        .collection("profiles")
                        .doc(key)
                        .update({
                          userHandle: userHandle.toLowerCase() || "",
                          headline: headline || "",
                          headerIntro: headerIntro || "",
                          location: location || "",
                          occupation: occupation || "",
                          emoji: emoji || "grinning",
                          linkedin: linkedin || "",
                          twitter: twitter || "",
                          instagram: instagram || "",
                        });
                    });
                }
              });
          });
      }

      setHandleError("");

      // setheadline
      setShowModal(false);
    });
  };

  return (
    <div>
      {" "}
      {editMode ? (
        <div>
          {" "}
          <button
            onClick={() => setShowModal(true)}
            className="button"
            style={{
              borderRadius: "50%",
              padding: "2px 10px",
              color: "#e2474b",
              fontSize: 20,
              fontWeight: "bold",
              borderWidth: 5,
              borderColor: "#e2474b",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)"
            }}
          >
            <img
              style={{
                width: 9
              }}
              src={require("../../images/pencil.png")}
            />{" "}
          </button>{" "}
        </div>
      ) : (
          <div> </div>
        )}
      <ReactModal
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
        className="Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        overlayClassName="Overlay"
        onRequestClose={() => setShowModal(false)}
        style={{
          width: "70%",
          justifyContent: "center",
          marginRight: 0
        }}
      >
        <div
          style={{
            width: "100%"
          }}
        >
          <form>
            <h4
              style={{
                marginBottom: 30,
                color: "#2f3a56",
                fontSize: 20
              }}
            >
              Edit Intro{" "}
            </h4>{" "}
            <label
              for="handle"
              style={{
                fontSize: 15
              }}
            >
              Handle{" "}
            </label>{" "}
            <input
              id="handle"
              type="text"
              style={{
                color: "#2f3a56"
              }}
              value={userHandle}
              placeholder="Insert your handle here"
              onChange={e => {
                setHandle(e.currentTarget.value);
              }}
            ></input>{" "}
            {handleError.length > 0 ? (
              <div style={{ color: "red" }}> {handleError} </div>
            ) : null}{" "}
            <label
              className="onboarding-label"
              for="emoji"
              style={{ fontSize: 15 }}
            >
              My spirit emoji is
            </label>
            <br />
            <button
              class="emoji-picker"
              onClick={e => {
                e.preventDefault();
                setPickerState(!pickerState);
              }}
            >
              <Emoji emoji={emoji || "grinning"} set="messenger" size={30} />
            </button>
            {pickerState ? (
              <Picker
                className="nav"
                title="Pick your emoji..."
                set="messenger"
                style={{ position: "absolute", top: "20%", left: "5%" }}
                onSelect={onEmojiClick}
              />
            ) : null}
            <br />
            <label
              className="onboarding-label"
              for="job"
              style={{ fontSize: 15 }}
            >
              Job
            </label>
            <input
              onKeyPress={checkAndHandleEnterKeyPress}
              id="job"
              type="text"
              placeholder="Senior UX Designer @ Pinterest"
              onChange={e => {
                setOccupation(e.currentTarget.value);
              }}
              value={occupation}
            />
            <br />
            <label
              className="onboarding-label"
              for="location"
              style={{ fontSize: 15 }}
            >
              Location (City, State)
            </label>
            <input
              onKeyPress={checkAndHandleEnterKeyPress}
              id="location"
              type="text"
              style={{ color: "#2f3a56" }}
              placeholder="San Francisco, CA"
              onChange={e => {
                setLocation(e.currentTarget.value);
              }}
              value={location}
            />
            <br />
            <label
              className="onboarding-label"
              for="linkedin"
              style={{ fontSize: 15 }}
            >
              LinkedIn
            </label>
            <input
              onKeyPress={checkAndHandleEnterKeyPress}
              id="linkedin"
              type="text"
              style={{ color: "#2f3a56" }}
              placeholder="LinkedIn URL"
              onChange={e => {
                setLinkedin(e.currentTarget.value);
              }}
              value={linkedin}
            />
            <br />
            <label
              className="onboarding-label"
              for="twitter"
              style={{ fontSize: 15 }}
            >
              Add your Twitter handle
            </label>
            <input
              onKeyPress={checkAndHandleEnterKeyPress}
              id="twitter"
              type="text"
              style={{ color: "#2f3a56" }}
              placeholder="@melindagates"
              onChange={e => {
                setTwitter(e.currentTarget.value);
              }}
              value={twitter}
            />
            <br />
            <label
              className="onboarding-label"
              for="instagram"
              style={{ fontSize: 15 }}
            >
              Add your Instagram handle
            </label>
            <input
              onKeyPress={checkAndHandleEnterKeyPress}
              id="instagram"
              type="text"
              style={{ color: "#2f3a56" }}
              placeholder="@melindagates"
              onChange={e => {
                setInstagram(e.currentTarget.value);
              }}
              value={instagram}
            />
            <div className="button-bar">
              <button
                onClick={onSubmit}
                className="pushpull-btn right"
                type="submit"
                name="action"
              >
                Save{" "}
              </button>{" "}
            </div>{" "}
          </form>{" "}
        </div>{" "}
      </ReactModal>{" "}
    </div>
  );
}

import firebase from "firebase";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAhsg4IDMpr0tlEZjMp4ESDoYdQFqGwA7A",
  authDomain: "warmly-dev.firebaseapp.com",
  databaseURL: "https://warmly-dev.firebaseio.com",
  projectId: "warmly-dev",
  storageBucket: "warmly-dev.appspot.com",
  messagingSenderId: "310020436142",
  appId: "1:310020436142:web:223122921cb700b7f0bd3f",
  clientId:
    "554335713891-76h5mlkh6sr4un512tdschu9bk5ubf8a.apps.googleusercontent.com"
});

// Still needs google app verification through gapi, requires a video. But this is intended to add contacts from google. Aim to add "frequently contacted" since nobody has google contacts
// export const uiConfig = {
//   signInFlow: "popup",
//   signInOptions: [
//     {
//       provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       scopes: ["https://www.googleapis.com/auth/contacts.readonly"],
//       customParameters: {
//         // Forces account selection even when one account
//         // is available.
//         prompt: "select_account"
//       }
//     }

//     // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//     // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
//     // firebase.auth.GithubAuthProvider.PROVIDER_ID,
//     // firebase.auth.EmailAuthProvider.PROVIDER_ID
//   ],
//   callbacks: {
//     signInSuccess: () => false
//   }
// };

export const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccess: () => false
  }
};

export const firebaseAuth = firebase.auth;
export default firebase;

// import firebase from "firebase";

// const config = {
//   apiKey: "AIzaSyAhsg4IDMpr0tlEZjMp4ESDoYdQFqGwA7A",
//   authDomain: "warmly-dev.firebaseapp.com",
//   databaseURL: "https://warmly-dev.firebaseio.com",
//   projectId: "warmly-dev",
//   storageBucket: "warmly-dev.appspot.com",
//   messagingSenderId: "310020436142",
//   appId: "1:310020436142:web:223122921cb700b7f0bd3f"
// };

// firebase.initializeApp(config);

// export const googleProvider = new firebase.auth.GoogleAuthProvider();
// export const ref = firebase.database().ref();
// export const firebaseAuth = firebase.auth;

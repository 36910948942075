import React, { useContext } from "react";
import ProgressBar from "../ProgressBar";

export default function ExplorePageCard(props) {
  const { values } = props;

  const Next = async e => {
    e.preventDefault();
    console.log("button pressed");
    props.nextStep();
  };
  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };

  const renderLink = () => {
    if ("communities" in props.profiles) {
      return (
        <div className="profile-helper-box-text">
          Step 1: Check out your{" "}
          <a href="http://pushpull.community/communities">Community</a> page!🎉
          <div className="profile-helper-box-subtext">
            To see how you can help other people on the PushPull network check
            out the <a href="http://pushpull.community/explore">Explore</a> page
          </div>
        </div>
      );
    } else {
      return (
        <div className="profile-helper-box-text">
          Step 1: Check out the{" "}
          <a href="http://pushpull.community/explore">Explore</a> page!🎉
          <br></br>
          <div className="profile-helper-box-subtext">
            See how you can help other people on the Warmly, network
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="profile-helper-box-title collection">
        How to get the most out of your PushPull Card in 3 simple steps!
      </div>
      <div className="profile-helper-box collection">
        <ProgressBar percent={values.percent} />
        <div style={{ clear: "both" }}></div>
        <div className="profile-helper-box-content">
          {renderLink()}
          <div className="arrow-container">
            <button className="arrow arrow-right" onClick={Next}></button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

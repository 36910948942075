import React, { useState, useEffect, useContext } from "react";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";
import ProgressBar from "../ProgressBar";
import { whitelisted_communities } from "./config";
import axios from "axios";
import { HOST_FIREBASE } from "../../config/firebaseHost";
const uuidv4 = require("uuid/v4");

export default function Success(props) {
  const { values, sets } = props;
  const { profiles } = useContext(AuthContext);
  const key = profiles.document_key;

  // useEffect(() => {
  //   const setLastOnboarding = async () => {
  //     if (!(Object.keys(profiles).length === 0)) {
  //       const ref = await firebase
  //         .firestore()
  //         .collection("profiles")
  //         .doc(profiles.document_key);
  //       ref.get().then(doc => {
  //         let profile = doc.data();
  //         if (!profile.hasOwnProperty("onboarding_6_success")) {
  //           let data = {
  //             last_onboarding_step: "onboarding_6_success"
  //           };
  //           ref.set(data, { merge: true });
  //         }
  //       });
  //     }
  //   };
  //   setTimeout(() => {
  //     setLastOnboarding();
  //   }, 5000);
  // }, [profiles]);

  useEffect(() => {
    setTimeout(async () => {
      const ref = await firebase
        .firestore()
        .collection("profiles")
        .doc(profiles.document_key);

      ref.get().then(doc => {
        let profile = doc.data();
        if (!profile.hasOwnProperty("onboarding_6_success")) {
          let data = {
            last_onboarding_step: "onboarding_6_success",
            onboarding_6_success: new Date()
          };
          ref.set(data, { merge: true });
        }
        axios
          .get(`${HOST_FIREBASE}/sendMail?dest=${profiles.email}`)
          .then(result => console.log("welcome email", result))
          .catch(err => console.log(err));
      }, [profiles]

      );

      // if (values.twitter.startsWith("@")) {
      //   let handle = values.twitter;
      //   handle = handle.substring(1)
      //   values.twitter = "http://www.twitter.com/" + handle
      // }
      // console.log("here's the url: ", values.twitter)
      // sets.setTwitter(values.twitter)

      //create new document for the userHandles collection, then update profiles collection
      console.log("here's the new userHandle", values.handle);
      firebase
        .firestore()
        .collection("userHandles")
        .doc(values.handle)
        .set({
          displayName: profiles.displayName,
          email: profiles.email,
          userHandle: values.handle,
          user: profiles.document_key,
          createdDate: new Date()
        })
        .then(result => {
          firebase
            .firestore()
            .collection("profiles")
            .doc(profiles.document_key)
            .get()
            .then(doc => {
              console.log("check");
              let data = {
                displayName: values.name || "",
                occupation: values.job || "",
                location: values.location || "",
                linkedin: values.linkedin || "",
                emoji: values.chosenEmoji || "",
                onboardingCompleted: true,
                userHandle: values.handle.toLowerCase() || "",
                push_personal: firebase.firestore.FieldValue.arrayUnion({
                  title: values.pushPersonalTitle,
                  content: values.pushPersonalContent,
                  isInEditMode: false,
                  id: uuidv4()
                }),
                push_professional: firebase.firestore.FieldValue.arrayUnion({
                  title: values.pushProfessionalTitle,
                  content: values.pushProfessionalContent,
                  isInEditMode: false,
                  id: uuidv4()
                }),
                pull_actionable: firebase.firestore.FieldValue.arrayUnion({
                  title: values.pullTitle,
                  content: values.pullContent,
                  isInEditMode: false,
                  id: uuidv4()
                }),
                tidBits: firebase.firestore.FieldValue.arrayUnion({
                  content: values.interestingContent,
                  isInEditMode: false,
                  id: uuidv4()
                })
              };

              if (values.community in whitelisted_communities) {
                data = { ...data, communities: [values.community] };
                console.log("saved community");
              } else {
                console.log("save to suggested communities");

                if (values.community.length > 0) {
                  let community_suggestions = {
                    createdDate: new Date(),
                    communitySuggestions: values.community,
                    displayName: profiles.displayName,
                    email: profiles.email,
                    source: "onboarding"
                  };

                  firebase
                    .firestore()
                    .collection("community_suggestions")
                    .doc(values.community)
                    .set({ ...community_suggestions });
                }
              }

              if (doc.data().userHandle === values.handle) {
                // user kept default userHandle
                console.log("not deleting");

                firebase
                  .firestore()
                  .collection("profiles")
                  .doc(key)
                  .update(data)
                  .then(result => {
                    console.log("when do we get here");
                    if (values.community in whitelisted_communities) {
                      window.location.assign("/communities");
                    } else {
                      window.location.assign("/edit");
                    }
                  });
              } else {
                console.log("deleting", doc.data().userHandle);
                firebase
                  .firestore()
                  .collection("userHandles")
                  .doc(doc.data().userHandle)
                  .delete()
                  .then(result => {
                    firebase
                      .firestore()
                      .collection("profiles")
                      .doc(key)
                      .update(data)
                      .then(result => {
                        console.log("when do we get here");
                        if (values.community in whitelisted_communities) {
                          window.location.assign("/communities");
                        } else {
                          window.location.assign("/edit");
                        }
                      });
                  });
              }
            });
        });

      // professional push
      let professional_push_data = {
        user: profiles.document_key,
        title: values.pushProfessionalTitle,
        action_type: "add",
        user_email: profiles.email,
        user_name: profiles.displayName,
        content: values.pushPersonalContent,
        type: "push_professional",
        onboardingFlag: true,
        createdDate: new Date()
      };
      const ref2 = firebase
        .firestore()
        .collection("pushes_pulls")
        .doc();
      ref2.set({ ...professional_push_data, document_key: ref2.id });

      // personal push
      let personal_push_data = {
        user: profiles.document_key,
        title: values.pushPersonalTitle,
        action_type: "add",
        user_email: profiles.email,
        user_name: profiles.displayName,
        content: values.pushPersonalContent,
        type: "push_personal",
        onboardingFlag: true,
        createdDate: new Date()
      };

      const ref3 = firebase
        .firestore()
        .collection("pushes_pulls")
        .doc();
      ref3.set({ ...personal_push_data, document_key: ref3.id });

      // actionable pull
      let pull_actionable = {
        user: profiles.document_key,
        title: values.pullTitle,
        action_type: "add",
        user_email: profiles.email,
        user_name: profiles.displayName,
        content: values.pullContent,
        type: "pull_actionable",
        onboardingFlag: true,
        createdDate: new Date()
      };

      const ref4 = firebase
        .firestore()
        .collection("pushes_pulls")
        .doc();
      ref4.set({ ...pull_actionable, document_key: ref4.id });
    }, 500);
  });
  return (
    <div>
      <ProgressBar percent={values.percent} />
      <div style={{ clear: "both" }}></div>
      <h4 className="onboarding-card-title">
        Great job! Here's what we've got so far...
      </h4>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";
import ProgressBar from "../ProgressBar";
const uuidv4 = require("uuid/v4");

export default function CommunitiesInstructions(props) {
  const { values, sets } = props;
  const { profiles } = useContext(AuthContext);

  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };
  const Next = async e => {
    e.preventDefault();

    props.nextStep();
  };

  return (
    <div>
      <ProgressBar percent={values.percent} />
      <div style={{ clear: "both" }}></div>
      <div style={{ clear: "both" }}></div>
      <h4 className="onboarding-card-title">
        {`You are part of the ${values.community} group`}
      </h4>
      <div class="button-bar">
        <button
          onClick={Next}
          className="pushpull-btn right"
          type="submit"
          name="action"
        >
          Continue
        </button>
        <button
          onClick={Previous}
          className="pushpull-btn secondary left"
          type="submit"
          name="action"
        >
          Back
        </button>
      </div>
    </div>
  );
}

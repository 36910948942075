import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../pages/Profile/HomePage";
import "./Personal.css";
import "./Notes.css";
import { AuthContext } from "../auth/AuthProvider";
import { firebaseAuth } from "../../config/constants";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import firebase from "../../config/constants";

export default function Notes(props) {
  const { notes, changeEditMode, deleteNote, setCurrentCardId } = props;
  const [loggedInUser, setLoggedInUser] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [content, setContent] = useState("");
  const { profiles } = useContext(AuthContext);
  let currentUser = firebaseAuth().currentUser;

  useEffect(() => {
    if (!(currentUser === null)) {
      if (profiles.uid === currentUser.uid) {
        setLoggedInUser(profiles);
      }
    }
  }, [profiles]);

  const followUp = [
    "push_professional",
    "push_personal",
    "pull_actionable",
    "pull_general",
    "pull_wild"
  ];

  const followUpMessage = {
    push_professional: "Pulling Your Push",
    push_personal: "Pulling Your Push",
    pull_actionable: "Pushing your Pull",
    pull_general: "Pushing your Pull",
    pull_wild: "Pushing your Pull"
  };

  let { editMode } = useContext(UserContext);

  const handleTitleChange = e => {
    setContentTitle(e.target.value);
  };

  const handleContentChange = e => {
    setContent(e.target.value);
  };

  const handleSubmit = id => {
    return event => {
      event.preventDefault();
      props.editNote(id, contentTitle, content, notes);
      setContentTitle(contentTitle);
      setContent(content);
    };
  };

  return notes.length ? (
    notes.map((note, index) => {
      let handle = note.content.match(/@\S+/g);
      if (handle) {
        handle = handle[0].replace("@", "") || "";
      }
      let contentTitle = note.title;
      let adjustedContent = note.content.replace(`@${handle}`, "");

      return note.isInEditMode ? (
        <div
          className="collection-item"
          style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          key={note.id}
        >
          <div style={{ flex: 10, marginTop: 20 }}>
            <form onSubmit={handleSubmit(note.id)}>
              <div className="note-card editing">
                <input
                  type="text"
                  defaultValue={note.title}
                  onChange={handleTitleChange}
                />
                <textarea
                  defaultValue={note.content}
                  onChange={handleContentChange}
                />
                <div className="button-bar" />
                <button
                  className="pushpull-btn left caution"
                  onClick={() => deleteNote(note.id, notes)}
                >
                  Delete entry
                </button>
                <button
                  className="pushpull-btn right"
                  type="submit"
                  name="action"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : editMode ? (
        <div
          className="collection-item left-align note-card editable"
          key={note.id}
          style={{
            borderLeft: "5px solid #406d96",
            paddingLeft: 20,
            borderWidth: 2.4,
            cursor: "pointer"
          }}
          onClick={() => {
            setCurrentCardId(note.id);
            changeEditMode(note.id, notes);
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 9 }}>
              <span style={{ fontFamily: "Raleway", fontSize: 15, height: 24 }}>
                <div style={{ fontSize: 18, fontWeight: 600 }}>
                  {contentTitle}
                </div>

                {props.title === "myPushers" ? (
                  <div style={{ fontSize: 15 }}>
                    {handle ? <a href={`/user/${handle}`}>@{handle}</a> : ""}{" "}
                    {adjustedContent}
                  </div>
                ) : (
                  <div style={{ fontSize: 15 }}>{note.content}</div>
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="row" style={{ marginBottom: 0 }}>
          <div
            className="collection-item left-align note-card view"
            key={note.id}
          >
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: 18, fontWeight: 600 }}>
                {contentTitle}
              </div>
              {props.title === "myPushers" ? (
                <div style={{ fontSize: 15 }}>
                  {handle ? <a href={`/user/${handle}`}>@{handle}</a> : ""}{" "}
                  {adjustedContent}
                </div>
              ) : (
                <div style={{ fontSize: 15 }}>{note.content}</div>
              )}
            </div>
            {/* Only show 'Follow up' if it's a Push or Pull (not TidBit or Pushers). */}
            {followUp.indexOf(props.title) >= 0 ? (
              <div
                className="collection-item left-align follow-up-container"
                key={note.id}
              >
                {/* {console.log("DOCUMENT_KEY: ", profiles.document_key)} */}
                <BrowserView>
                  <div
                    onClick={async e => {
                      let data = {
                        receiver: profiles.document_key,
                        receiverEmail: profiles.email,
                        sender: loggedInUser.document_key || "",
                        senderEmail: loggedInUser.email || "",
                        type: props.title,
                        content_title: note.title || "",
                        content: note.content,
                        page: "user_profile",
                        platform: "browser",
                        createdDate: new Date()
                      };

                      const ref = await firebase
                        .firestore()
                        .collection("follow_ups")
                        .doc();
                      ref.set({ ...data, document_key: ref.id });
                    }}
                  >
                    <a
                      target="_blank"
                      className="follow-up-btn pushpull-btn secondary"
                      style={{ textDecoration: "none" }}
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${
                        profiles.email
                      }&su=${followUpMessage[props.title]}${
                        note.title ? `: ${note.title}` : ""
                      }&body=Hi ${profiles.displayName
                        .split(" ")
                        .slice(0, -1)
                        .join(
                          " "
                        )},%0D%0A%0D%0AI saw your PushPull and <REPLACE TEXT>  %0D%0A%0D%0AWarmly,%0D%0A${
                        currentUser === null ? `` : loggedInUser.displayName
                      }%0D%0A${
                        currentUser === null
                          ? ``
                          : `My PushPull Card: https://pushpull.community/user/${loggedInUser.userHandle}`
                      }`}
                    >
                      Follow Up
                    </a>
                  </div>
                </BrowserView>
                <MobileView>
                  <div
                    onClick={async e => {
                      let data = {
                        receiver: profiles.document_key,
                        receiverEmail: profiles.email,
                        sender: loggedInUser.document_key,
                        senderEmail: loggedInUser.email,
                        type: props.title,
                        content_title: note.title || "",
                        content: note.content,
                        page: "user_profile",
                        platform: "mobile",
                        createdDate: new Date()
                      };
                      const ref = await firebase
                        .firestore()
                        .collection("follow_ups")
                        .doc();
                      ref.set({ ...data, document_key: ref.id });
                    }}
                  >
                    <a
                      // target="_blank"
                      className="follow-up-btn pushpull-btn secondary"
                      href={`mailto:${profiles.email}?subject=${
                        followUpMessage[props.title]
                      }${note.content}&body=Hi ${profiles.displayName
                        .split(" ")
                        .slice(0, -1)
                        .join(
                          " "
                        )},%0D%0A%0D%0AI saw your PushPull and <REPLACE TEXT>  %0D%0A%0D%0AWarmly,%0D%0A${
                        currentUser === null ? `` : loggedInUser.displayName
                      }%0D%0A${
                        currentUser === null
                          ? ``
                          : `My PushPull Card: https://pushpull.community/user/${loggedInUser.userHandle}`
                      }`}
                    >
                      Follow Up
                    </a>
                  </div>
                </MobileView>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      );
    })
  ) : (
    <div>{/* <p className="center"> You should fill this out 😃</p> */}</div>
  );
}

import React, { useEffect } from "react";
import NavBar from "../../components/navbar/NavBar";

export default function SlackPage(props) {
  return (
    <div style={{ paddingBottom: 50 }}>
      <NavBar />
      <div className="home-page ">
        <h1 style={{ marginTop: 250 }}>Install Pinguin on Slack</h1>
        <h3>
          Pinguin is a Slack bot by PushPull that helps build a give first
          culture within your organization.
        </h3>
        <h3>Feed Pinguin a fish and watch him grow by helping others!</h3>
        <br />
        <img
          style={{ width: 20, cursor: "pointer", width: 100, height: 100 }}
          src={require("../../images/pinguin.png")}
        />
        <div style={{ marginTop: 30 }}></div>

        {/* <a href="https://slack.com/oauth/v2/authorize?client_id=718420716803.934048228483&scope=app_mentions:read,calls:write,channels:read,chat:write,groups:write,im:history,im:write,incoming-webhook,mpim:write,users.profile:read,users:read,users:write,channels:history&user_scope=chat:write,im:history,channels:history&redirect_uri=https%3A%2F%2Fus-central1-warmly-dev.cloudfunctions.net%2FslackAuth"></a> */}
        <a href="https://slack.com/oauth/v2/authorize?client_id=718420716803.934048228483&scope=app_mentions:read,calls:write,channels:read,chat:write,groups:write,im:history,im:write,incoming-webhook,mpim:write,users.profile:read,users:read,users:write,channels:history&user_scope=chat:write,im:history,channels:history">
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const CommunityRoute = ({ component: RouteComponent, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [rightCommunity, setRightCommunity] = useState(false);

  const { currentUser, profiles } = useContext(AuthContext);

  useEffect(() => {
    if (!(Object.keys(profiles).length === 0)) {
      console.log(rest.community in profiles.communities);
      if (profiles.communities.indexOf(rest.community) >= 0) {
        console.log("heip");
        setRightCommunity(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [profiles]);

  return loading ? (
    <div></div>
  ) : (
    <Route
      {...rest}
      render={routeProps => {
        return !!currentUser && rightCommunity ? (
          <RouteComponent community={"on_deck"} {...routeProps} />
        ) : (
          <Redirect exact={true} to={"/communities"} />
        );
      }}
    />
  );
};

export default CommunityRoute;

import React, { useState, useEffect, useContext } from "react";
import NavBar from "../navbar/NavBar";
import FormPersonalDetails from "./FormPersonalDetails";
import ProfessionalPush from "./ProfessionalPush";
import PersonalPush from "./PersonalPush";
import Pull from "./Pull";
import Tidbit from "./Tidbit";
import Success from "./Success";
import "./UserForm.css";
import { AuthContext } from "../auth/AuthProvider";
import CommunitiesInstructions from "./CommunitiesInstructions";
import { whitelisted_communities } from "./config";
import WelcomeCommunities from "./WelcomeCommunities";

export default function UserForm(props) {
  const { currentUser, profiles } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [percent, setPercent] = useState("");
  const [name, setName] = useState(currentUser.displayName);
  const [handle, setHandle] = useState(profiles.userHandle);
  const [emoji, setEmoji] = useState("");
  const [job, setJob] = useState("");
  const [location, setLocation] = useState("");
  const [community, setCommunity] = useState("");
  const [linkedin, setLinkedin] = useState("");
  //const [twitter, setTwitter] = useState("");
  const [pushProfessionalTitle, setProfessionalPushTitle] = useState("");
  const [pushProfessionalContent, setProfessionalPushContent] = useState("");
  const [pushPersonalTitle, setPersonallPushTitle] = useState("");
  const [pushPersonalContent, setPersonallPushContent] = useState("");
  const [pullTitle, setPullTitle] = useState("");
  const [pullContent, setPullContent] = useState("");
  const [interestingContent, setInterestingContent] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState("grinning");

  useEffect(() => {
    if (step === numOnboardingPages) {
      setPercent(100);
      // } else if (step === "community_onboarding") {
      //   setPercent((1 * 100) / (numOnboardingPages - 1));
    } else {
      setPercent((step * 100) / (numOnboardingPages - 1));
    }
  }, [step]);

  //used for progressbar
  const numOnboardingPages = 6;

  // proceed to next step
  const nextStep = () => {
    setStep(step + 1);
    setPercent((step * 100) / (numOnboardingPages - 1));
    // if ((step === 1) & (community in whitelisted_communities)) {
    //   setStep("community_onboarding");
    //   setPercent((1 * 100) / (numOnboardingPages - 1));
    // } else if (step === "community_onboarding") {
    //   setStep(2);
    // } else {
    //   setStep(step + 1);
    //   setPercent((step * 100) / (numOnboardingPages - 1));
    // }
  };

  // proceed to previous step
  const previousStep = () => {
    setStep(step - 1);
    // if (step === "community_onboarding") {
    //   setStep(1);
    // } else if (step === 2) {
    //   setStep("community_onboarding");
    //   setPercent((1 * 100) / (numOnboardingPages - 1));
    // } else {
    //   setStep(step - 1);
    // }
  };

  // handle field change
  const handleChange = input => e => {
    console.log(input, e);
  };

  const values = {
    percent,
    name,
    handle,
    emoji,
    job,
    location,
    community,
    linkedin,
    //twitter,
    pushProfessionalTitle,
    pushProfessionalContent,
    pushPersonalTitle,
    pushPersonalContent,
    pullTitle,
    pullContent,
    interestingContent,
    chosenEmoji
  };
  const sets = {
    setPercent,
    setName,
    setHandle,
    setEmoji,
    setJob,
    setLocation,
    setCommunity,
    setLinkedin,
    //setTwitter,
    setProfessionalPushTitle,
    setProfessionalPushContent,
    setPersonallPushTitle,
    setPersonallPushContent,
    setPullTitle,
    setPullContent,
    setInterestingContent,
    setChosenEmoji
  };

  var onboardingCard;
  var welcome;

  switch (step) {
    case 1:
      onboardingCard = (
        <FormPersonalDetails
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case "community_onboarding":
      onboardingCard = (
        <CommunitiesInstructions
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;
    case 2:
      welcome = (
        <WelcomeCommunities
          values={values}
        />
      )
      onboardingCard = (
        <div>
          <ProfessionalPush
            nextStep={nextStep}
            previousStep={previousStep}
            handleChange={handleChange}
            values={values}
            sets={sets}
          />
        </div>
      );
      break;

    case 3:
      onboardingCard = (
        <PersonalPush
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case 4:
      onboardingCard = (
        <Pull
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case 5:
      onboardingCard = (
        <Tidbit
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case 6:
      onboardingCard = (
        <Success
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;
  }

  return (
    <div className="onboarding-page">
      <NavBar />
      <div className="onboarding-card">{onboardingCard}</div>
    </div>
  );
}

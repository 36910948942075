import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../components/auth/AuthProvider";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import firebase from "../../config/constants";
import NavBar from "../../components/navbar/NavBar";

export default function ExploreFeed(props) {
  const [loggedInUser, setLoggedInUser] = useState("");
  const [loggedInUserFirstName, setLoggedInUserFirstName] = useState("");
  const { currentUser } = useContext(AuthContext);
  const { profiles } = useContext(AuthContext);

  // List of user handles to hide from public Explore page b/c user said they're too busy to respond right now.
  const profilesUsersRequestedToHide = ["julia"];

  const onClickPushPullActivity = (e, userHandle) => {
    if (!e.target.classList.contains("follow-up-btn")) {
      // Need to this check to prevent routing to user profile page when user is tapping 'Follow up' button.
      routeToUserProfile(userHandle);
    }
  };
  const routeToUserProfile = userHandle => {
    window.location = `/user/${userHandle}`;
  };

  /* TODO: Move follow up into it's own component to not repeat this with Notes.js */
  const followUpMessage = {
    push_professional: "Pulling Your Push",
    push_personal: "Pulling Your Push",
    pull_actionable: "Pushing your Pull",
    pull_general: "Pushing your Pull",
    pull_wild: "Pushing your Pull"
  };

  useEffect(() => {
    if (!(currentUser === null)) {
      if (profiles.uid === currentUser.uid) {
        setLoggedInUser(profiles);
        // Get the user's first name instead of full name.
        var lastIndex = profiles.displayName.lastIndexOf(" ");
        setLoggedInUserFirstName(profiles.displayName.substring(0, lastIndex));
      }
    }
  }, [profiles]);

  const component = (type, right) => {
    console.log("props?", props.pushes);
    return (
      <div style={!right ? { flex: 1 } : { flex: 1, marginLeft: 20 }}>
        {props[type].map((item) => {
          if (profilesUsersRequestedToHide.includes(item.user.handle)) {
            return;
          }
          return (
            <div
              className="collection-item card clickable community-card activity-card"
              onClick={e => onClickPushPullActivity(e, item.user.handle)}
            >
              <div className="activity-header">
                <img
                  className="user-image activity-header-user-image profile-image"
                  src={item.user.photoURL}
                />
                <div className="activity-header-user-info">
                  <div className="activity-header-user-name">
                    {item.user.displayName}
                  </div>
                  <div>
                    {item.user.occupation} • {item.user.location}
                  </div>
                  {/* <div className="activity-header-timestamp">
                      {moment(item.createdDate.toDate()).from(new Date())}
                    </div> */}
                </div>
                <div
                  className={"pushpull-type " + "pushpull-type-" + item.type}
                >
                  {item.type == "PUSH" ? "🎁PUSH" : "🙌PULL"}
                </div>
              </div>
              <div className="activity-content">
                <div className="pushpull-title">{item.title}</div>
                <div className="pushpull-text">{item.content}</div>
              </div>
              <div className="activity-actions">
                <div
                  className="collection-item left-align follow-up-container"
                  key={item.id}
                >
                  <BrowserView>
                    <div
                      onClick={async e => {
                        let data = {
                          receiver: item.user.id,
                          receiverEmail: item.user.email,
                          sender: loggedInUser.document_key || "",
                          senderEmail: loggedInUser.email || "",
                          type: item.typeId,
                          content_title: item.title || "",
                          content: item.content,
                          page: "community_feed",
                          platform: "browser",
                          createdDate: new Date()
                        };

                        const ref = await firebase
                          .firestore()
                          .collection("follow_ups")
                          .doc();
                        ref.set({ ...data, document_key: ref.id });
                      }}
                    >
                      <a
                        target="_blank"
                        className="follow-up-btn pushpull-btn secondary"
                        style={{ textDecoration: "none" }}
                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${
                          item.user.email
                        }&su=${followUpMessage[item.typeId]}${
                          item.title ? `: ${item.title}` : ""
                        }&body=Hi ${item.user.displayName
                          .split(" ")
                          .slice(0, -1)
                          .join(
                            " "
                          )},%0D%0A%0D%0AI saw your PushPull and <REPLACE TEXT>  %0D%0A%0D%0AWarmly,%0D%0A${
                          currentUser === null ? `` : loggedInUser.displayName
                        }%0D%0A${
                          currentUser === null
                            ? ``
                            : `My PushPull card: https://pushpull.community/user/${loggedInUser.userHandle}`
                        }`}
                      >
                        Follow Up
                      </a>
                    </div>
                  </BrowserView>
                  <MobileView>
                    <div
                      onClick={async e => {
                        let data = {
                          receiver: item.user.id,
                          receiverEmail: item.user.email,
                          sender: loggedInUser.document_key || "",
                          senderEmail: loggedInUser.email || "",
                          type: item.typeId,
                          content_title: item.title || "",
                          content: item.content,
                          page: "community_feed",
                          platform: "mobile",
                          createdDate: new Date()
                        };
                        const ref = await firebase
                          .firestore()
                          .collection("follow_ups")
                          .doc();
                        ref.set({ ...data, document_key: ref.id });
                      }}
                    >
                      <a
                        target="_blank"
                        className="follow-up-btn pushpull-btn secondary"
                        style={{ textDecoration: "none" }}
                        href={`mailto:${item.user.email}?subject=${
                          followUpMessage[item.typeId]
                        }${
                          item.title ? `: ${item.title}` : ""
                        }&body=Hi ${item.user.displayName
                          .split(" ")
                          .slice(0, -1)
                          .join(
                            " "
                          )},%0D%0A%0D%0AI saw your PushPull and <REPLACE TEXT>  %0D%0A%0D%0AWarmly,%0D%0A${
                          currentUser === null ? `` : loggedInUser.displayName
                        }%0D%0A${
                          currentUser === null
                            ? ``
                            : `My PushPull card: https://pushpull.community/user/${loggedInUser.userHandle}`
                        }`}
                      >
                        Follow Up
                      </a>
                    </div>
                  </MobileView>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <NavBar />
      <div class="feed-content">
        <h1 className="community-name">Explore</h1>
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            onChange={e => props.activitySearch(e)}
            placeholder="Search people, topics, location"
            value={props.aQuery}
          />
        </div>
        {loggedInUser ? (
          <div className="collection-item card community-card activity-update-profile-card">
            <div className="activity-update-profile-header">
              Update your PushPull
            </div>
            <div className="activity-update-profile-body">
              <div className="activity-update-profile-body-content">
                <img
                  className="user-image activity-header-user-image profile-image"
                  src={loggedInUser.photoURL}
                  style={{ cursor: "pointer" }}
                  onClick={e => window.location.assign("/edit")}
                />
                <div className="activity-update-profile-body-content-prompt">
                  What would you like help with today, {loggedInUserFirstName}?
                </div>
              </div>
              <div className="activity-update-profile-body-actions">
                <button
                  class="pushpull-btn secondary"
                  style={{ fontWeight: "bold" }}
                  onClick={e => window.location.assign("/edit")}
                >
                  🎁Add a Push
                </button>
                <button
                  class="pushpull-btn secondary"
                  style={{ fontWeight: "bold" }}
                  onClick={e => window.location.assign("/edit")}
                >
                  🙌Add a Pull
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <BrowserView>
            <div className="flex-right">
              {component("pushes")}
              {component("pulls", "right")}
            </div>
          </BrowserView>
          <MobileView>
            <div>{component("allActivity")}</div>
          </MobileView>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import "./Communities.css";
import firebase from "../../config/constants";
import NavBar from "../../components/navbar/NavBar";
import { AuthContext } from "../../components/auth/AuthProvider";
import Members from "../../components/communities/members";
import Sidebar from "../../components/communities/sidebar";
import Activity from "../../components/communities/activity";
import { whitelisted_communities } from "../../components/onboarding/config.js";

export const UserContext = React.createContext();

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export default function Communities(props) {
  const { profiles, setProfiles, currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const userHandleParam = props.match.params.userHandleParam || "";
  const [members, setMembers] = useState([]);
  const [allActivity, setAllActivity] = useState([]);
  const [tab, setTab] = useState("activity");
  const [memberQuery, setMemberQuery] = useState([]);
  const [activityQuery, setActivityQuery] = useState([]);
  const [mQuery, setMQuery] = useState("");
  const [aQuery, setAQuery] = useState("");
  const [pushes, setPushes] = useState([]);
  const [pulls, setPulls] = useState([]);
  const [community, setCommunity] = useState("");
  const [communityCode, setCommunityCode] = useState("");

  /**
   * Returns a push/pull activty object with relevant data to be shown in Activity Feed.
   * @param {*} pushpull Push/Pull from database.
   * @param {*} user Profile from database.
   * @return {*} Push/Pull activity object.
   */
  const generatePushPullActivityObject = (pushpull, pushpullType, user) => {
    let c = {};
    c.user = {};

    c.user.id = user.document_key;
    c.user.email = user.email;
    c.user.displayName = user.displayName;
    c.user.occupation = user.occupation;
    c.user.location = user.location;
    c.user.photoURL = user.photoURL;
    c.user.handle = user.userHandle;

    c.id = pushpull.id;
    c.createdDate = pushpull.createdDate;
    c.content = pushpull.content;
    c.title = pushpull.title;
    c.typeId = pushpullType;
    switch (pushpullType) {
      case "push_personal":
        c.category = "PERSONAL";
        c.type = "PUSH";
        break;
      case "push_professional":
        c.category = "PROFESSIONAL";
        c.type = "PUSH";
        break;
      case "pull_actionable":
        c.category = "ACTIONABLE";
        c.type = "PULL";
        break;
      case "pull_general":
        c.category = "GENERAL";
        c.type = "PULL";
        break;
      case "pull_wild":
        c.category = "WILD";
        c.type = "PULL";
        break;
    }

    return c;
  };

  const selectCommunity = (community) => {
    setCommunityCode(community);
  };

  useEffect(() => {
    const fetchData = async () => {
      let searchCommunity = communityCode;

      if (!searchCommunity) {
        if (Object.keys(profiles).length) {
          if (!profiles.communities) {
            // If the user does not belong to a Community, redirect them to Explore.
            window.location.assign("/explore");
          } else {
            console.log("Test:", profiles.communities);
            searchCommunity = props.community || profiles.communities[0];
          }
        }
      }

      if (searchCommunity) {
        let title = whitelisted_communities[searchCommunity].name;
        setCommunity(title);
        const snapshot = await firebase
          .firestore()
          .collection("profiles")
          .where("communities", "array-contains", searchCommunity)
          .get();
        let members = [];
        snapshot.docs.map((doc) => {
          let user = doc.data();
          members.push(user);
        });
        setMembers(members);
        setMemberQuery(members);

        let pushes = [];
        let pulls = [];
        let all = [];

        members.map((member) => {
          for (let push of member.push_personal) {
            let c = generatePushPullActivityObject(
              push,
              "push_personal",
              member
            );
            pushes.push(c);
            // if (c.createdDate) {
            all.push(c);
            // }
          }
          for (let push of member.push_professional) {
            let c = generatePushPullActivityObject(
              push,
              "push_professional",
              member
            );
            pushes.push(c);
            // if (c.createdDate) {
            all.push(c);
            // }
          }

          for (let pull of member.pull_actionable) {
            let c = generatePushPullActivityObject(
              pull,
              "pull_actionable",
              member
            );
            pulls.push(c);
            // if (c.createdDate) {
            all.push(c);
            // }
          }
          for (let pull of member.pull_general) {
            let c = generatePushPullActivityObject(
              pull,
              "pull_general",
              member
            );
            pulls.push(c);
            // if (c.createdDate) {
            all.push(c);
            // }
          }
          for (let pull of member.pull_wild) {
            let c = generatePushPullActivityObject(pull, "pull_wild", member);
            pulls.push(c);
            // if (c.createdDate) {
            all.push(c);
            // }
          }
        });

        // const all_sorted = all.sort(function (a, b) {
        //   if (a.createdDate) {
        //     return b.createdDate.toDate() - a.createdDate.toDate();
        //   } else {
        //     return;
        //   }
        // });

        setPushes(shuffle(pushes));
        setPulls(shuffle(pulls));
        setAllActivity(shuffle(all));
        setActivityQuery(all);

        // setAllActivity(all_sorted);
        // setActivityQuery(all_sorted);
      }
    };
    fetchData();
  }, [profiles, communityCode]);

  const memberSearch = async (e) => {
    let query = e.currentTarget.value;
    console.log("here's the member query", query);
    setMQuery(query);
    let newMembers = [];

    for (let member of members) {
      if (
        member.displayName.toLowerCase().includes(query.toLowerCase()) ||
        member.occupation.toLowerCase().includes(query.toLowerCase())
      ) {
        newMembers.push(member);
      }
    }
    console.log(newMembers);
    setMemberQuery(newMembers);
  };

  const activitySearch = async (e) => {
    let query = e.currentTarget.value;
    setAQuery(query);
    let newActivities = [];
    let newPushes = [];
    let newPulls = [];

    for (let item of allActivity) {
      if (
        (item.user.displayName &&
          item.user.displayName.toLowerCase().includes(query.toLowerCase())) ||
        (item.user.occupation &&
          item.user.occupation.toLowerCase().includes(query.toLowerCase())) ||
        (item.user.location &&
          item.user.location.toLowerCase().includes(query.toLowerCase())) ||
        (item.content &&
          item.content.toLowerCase().includes(query.toLowerCase())) ||
        (item.title && item.title.toLowerCase().includes(query.toLowerCase()))
      ) {
        newActivities.push(item);
        if (item.type === "PUSH") {
          newPushes.push(item);
        } else {
          newPulls.push(item);
        }
      }
    }
    console.log("get new activities", newActivities);
    setPushes(newPushes);
    setPulls(newPulls);
    setActivityQuery(newActivities);
  };

  var menuTab;

  switch (tab) {
    case "members":
      menuTab = (
        <Members
          community={community}
          members={memberQuery}
          memberSearch={memberSearch}
          mQuery={mQuery}
        />
      );
      break;
    case "activity":
      menuTab = (
        <Activity
          community={community}
          pushes={pushes}
          pulls={pulls}
          allActivity={activityQuery}
          activitySearch={activitySearch}
          aQuery={aQuery}
        />
      );
      break;
  }

  return loading ? null : (
    <div>
      <UserContext.Provider
        value={{
          userHandleParam: userHandleParam,
          profiles: profiles,
        }}
      >
        <NavBar />

        <Sidebar setTab={setTab} />

        <div className="community-page-container">
          {profiles.communities && profiles.communities.length > 1 ? (
            <div className="community-action-container">
              {profiles.communities.map((community) => (
                <button
                  className="pushpull-btn secondary"
                  onClick={(e) => selectCommunity(community)}
                >
                  {community}
                </button>
              ))}
            </div>
          ) : null}
          {menuTab}
        </div>
      </UserContext.Provider>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/AuthProvider";

export default function Members(props) {
  const { profiles } = useContext(AuthContext);

  return (
    <div>
      <div class="content">
        <h1 className="community-name">{props.community}</h1>
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            onChange={e => props.memberSearch(e)}
            placeholder="Search"
            value={props.mQuery}
          />
        </div>
        <div class="member-display">
          {props.members.map(member => {
            return (
              <a
                className="member-card-wrapper"
                href={`/user/${member.userHandle}`}
              >
                <div className="collection-item left-align card clickable community-card member-card view">
                  <div className="img-container">
                    <img
                      className="user-image profile-image"
                      src={member.photoURL}
                    />
                  </div>
                  <div className="member-bio">
                    <div className="member-name">{member.displayName}</div>
                    <div className="member-job">{member.occupation}</div>
                    <div className="member-subtext">{member.location}</div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const whitelisted_communities = {
  YCFF: {
    name: "YCombinator Female Founders",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2FYCombinator.png?alt=media&token=d5af3584-fe84-4ef2-80fb-fa5b77407ab2",
    push_professional: {
      title: "Techstack advice for startups",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "Female founder mentors",
      description:
        "I'm in a team of all male founders. I'd love to talk to other female founders about tips on building team culture, having a balanced voice on the team, etc.",
    },
  },
  YCS20: {
    name: "YCombinator S20",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2FYCombinator.png?alt=media&token=d5af3584-fe84-4ef2-80fb-fa5b77407ab2",
    push_professional: {
      title: "Techstack advice for startups",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "Sales strategy advice",
      description:
        "My cofounder and I are both technical cofounders. It's our first time doing B2B sales. Would love advice on what works and what doesn't.",
    },
  },
  CODEPATH102: {
    name: "CodePath SE102 2020",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2FCodePath.png?alt=media&token=1eaf4890-e2ca-4722-96ae-48af6c0d9083",
    push_professional: {
      title: "Resume critique",
      description:
        "I've gotten a lot of good advice on how to make a great resume. Happy to review your resume and share all the tips I've learned!",
    },
    pull_professional: {
      title: "Mock onsite interview",
      description:
        "I'm able to get past the initial phone screen interview, but not the onsite. Would really appreciate a mock interview and feedback on how I can do better",
    },
  },
  techstars: {
    name: "Techstars",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Ftechstars.png?alt=media&token=2e95cf2c-ccbd-4074-adb0-f17e15d3dcad",
    push_professional: {
      title: "Techstack advice for startups",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "One day a week gig at a bakery",
      description:
        "Ideally in the morning. I'd love to work a half-day shift at a bakery 🍪",
    },
  },
  on_deck: {
    name: "On Deck",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2FOnDeckLogo.png?alt=media&token=211f798e-6ffd-48ff-a838-d75f96cd73db",
    push_professional: {
      title: "Technical Advice",
      description:
        "I’m a technical founder w/ PM background. After selling my last company in Big Data and taking time off, I’m thinking about what’s next.",
    },
    pull_professional: {
      title: "Mentor for first-time founders",
      description:
        "Intro to a friend who's started a company or is an investor and willing to give advice to first-time founders.",
    },
  },
  SFBAYRE: {
    name: "SF Bay Real Estate",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Fsf_bay_real_estate.png?alt=media&token=027b7c75-3f4c-458d-8832-50dd7d0f3f83",
    push_professional: {
      title: "Intros to real estate investors in Bay Area",
      description:
        "I have helped hundreds on investors in the US, and can introduce you to people you'd like to talk to with your specific investment strategy!",
    },
    pull_professional: {
      title: "Closing deals with development potential",
      description:
        "I'm looking for clients that may want financing for their projects!",
    },
  },
  SFGIRLBOSSES: {
    name: "SF Girl Bosses",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Fsf_girl_bosses.png?alt=media&token=7ebf78f2-ebfc-4783-ae7d-d50d4719beb5",
    push_professional: {
      title: "Influencer Marketing & Building Organic Online Communities",
      description:
        "I've spent many years building online communities. I'd love to share tips with you on building community in today's digital world, influencer marketing, building UGC (user generated content), and leveraging your community to gain insights and spread the word.",
    },
    pull_professional: {
      title: "Advice on entrepeneurship",
      description:
        "I'm considering starting my own business. Would love to talk to other entrepeneurs about the highs and lows of entrepeneurship, learn what startup life is like, and get advice.",
    },
  },
  FUTURECAREERS: {
    name: "Future Careers",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Ffuture_careers.png?alt=media&token=a2e39f97-7562-4192-bca0-012305d68c06",
    push_professional: {
      title: "Techstack advice for startups",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "One day a week gig at a bakery",
      description:
        "Ideally in the morning. I'd love to work a half-day shift at a bakery 🍪",
    },
  },
  REDOX: {
    name: "Redox",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Fredox.png?alt=media&token=794517fe-cb2a-4d2d-9caa-e546df4a9e8c",
    push_professional: {
      title: "Techstack advice for startups",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "One day a week gig at a bakery",
      description:
        "Ideally in the morning. I'd love to work a half-day shift at a bakery 🍪",
    },
  },
  SIFTDESIGN: {
    name: "Sift",
    logo:
      "https://firebasestorage.googleapis.com/v0/b/warmly-dev.appspot.com/o/assets%2Fsift.png?alt=media&token=a2859148-8537-4dea-8845-9850c10949ed",
    push_professional: {
      title: "Techstack advice for engineers",
      description:
        "I've been a fullstack engineer for 6 years and can give you tips of choosing the best techstack for your company",
    },
    pull_professional: {
      title: "One day a week gig at a bakery",
      description:
        "Ideally in the morning. I'd love to work a half-day shift at a bakery 🍪",
    },
  },
};

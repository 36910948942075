import React, { useState, useContext, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { validateProfessionalPush } from "../../schemas/onboarding";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";
import { whitelisted_communities } from "./config";

const items = [
  "Product/Design Feedback",
  "Intros to investors in Silicon Valley",
  "Fundraising Advice",
  "Coding Interview Prep",
  "Leadership Coaching Session",
  "30 minute Python session"
];

export default function ProfessionalPush(props) {
  const { profiles } = useContext(AuthContext);
  const [handleError, setHandleError] = useState("");
  const { values, sets } = props;

  useEffect(() => {
    const setLastOnboarding = async () => {
      if (!(Object.keys(profiles).length === 0)) {
        await console.log("part2", profiles);
        const ref = await firebase
          .firestore()
          .collection("profiles")
          .doc(profiles.document_key);
        ref.get().then(doc => {
          let profile = doc.data();
          if (!profile.hasOwnProperty("onboarding_2_professionalPush")) {
            let data = {
              last_onboarding_step: "onboarding_2_professionalPush"
            };
            ref.set(data, { merge: true });
          }
        });
      }
    };
    setTimeout(() => {
      setLastOnboarding();
    }, 100);
  }, [profiles]);

  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };
  const Next = async e => {
    e.preventDefault();
    try {
      const isValid = await validateProfessionalPush.validate(
        {
          pushProfessionalTitle: values.pushProfessionalTitle,
          pushProfessionalContent: values.pushProfessionalContent
        },
        {
          abortEarly: false
        }
      );

      if (!isValid) {
        return;
      }
    } catch (err) {
      console.log(err.errors[0]);
      setHandleError(err.errors[0]);
      return;
    }

    const ref = await firebase
      .firestore()
      .collection("profiles")
      .doc(profiles.document_key);

    ref.get().then(doc => {
      let profile = doc.data();
      if (!profile.hasOwnProperty("onboarding_2_professionalPush")) {
        let data = {
          onboarding_2_professionalPush: new Date()
        };
        ref.set(data, { merge: true });
      }
    });

    props.nextStep();
  };

  const handleSuggestionClick = async e => {
    await sets.setProfessionalPushTitle(e.target.id);
  };

  return (
    <div>
      <div className="onboarding-progress-bar">
        <ProgressBar percent={values.percent} />
        {values.community in whitelisted_communities ? (
          < div >
            <img
              className="onboarding-community-logo"
              src={whitelisted_communities[values.community].logo}
            />
            <div className="onboarding-pushpull-community-notice">
              <h3>
                Welcome to the {whitelisted_communities[values.community].name}{" "}
                PushPull community! 🎉
            </h3>
              <div style={{ marginBottom: 4 }}>
                List things you can help with (<b>pushes</b>) & things you’d like
              help with (<b>pulls</b>).
            </div>
              <div>
                Everything you list here will be private—only{" "}
                {whitelisted_communities[values.community].name} members will see
                it.
            </div>
            </div>
          </div>
        ) : null
        }
        <div style={{ clear: "both" }}></div>
      </div>
      <form>
        <h4 className="onboarding-card-title">
          <div className="onboarding-card-category">🎁 Add a Push: </div>
          <div>What's a <span className="onboarding-card-accent"> professional </span> favor you can offer to{" "}
            {values.community in whitelisted_communities
              ? "people at " + whitelisted_communities[values.community].name
              : "others"}
            ?
          </div>
          {values.community in whitelisted_communities ? (
            <div className="onboarding-pushpull-privacy">
              <i className="fa fa-lock"></i> Only visible to{" "}
              {whitelisted_communities[values.community].name}
            </div>
          ) : null}
        </h4>
        <input
          onKeyPress={e => {
            e.target.keyCode === 13 && e.preventDefault();
          }}
          autoFocus="autofocus"
          id="pushProfessionalTitle"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder={
            values.community in whitelisted_communities ? whitelisted_communities[values.community].push_professional.title :
              "Public Speaking"
          }
          onChange={e => {
            sets.setProfessionalPushTitle(e.currentTarget.value);
          }}
          value={values.pushProfessionalTitle}
        />

        <br />
        <textarea
          id="pushProfessionalContent"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder={
            values.community in whitelisted_communities ? whitelisted_communities[values.community].push_professional.description :
              "I regularly speak at conferences and am happy to help you create attractive presentations and articulate your story to emphasize your key takeaways."
          }
          onChange={e => {
            sets.setProfessionalPushContent(e.currentTarget.value);
          }}
          value={values.pushProfessionalContent}
        />
        {handleError.length > 0 ? (
          <div className="handle-error-text">{handleError}</div>
        ) : (
            <div />
          )}

        <div class="button-bar">
          <button
            onClick={Next}
            className="pushpull-btn right"
            type="submit"
            name="action"
          >
            Continue
          </button>
          <button
            onClick={Previous}
            className="pushpull-btn secondary left"
            type="submit"
            name="action"
          >
            Back
          </button>
        </div>
      </form>

      <div className="onboarding-container">
        <div>Stuck? Build off of one of our suggestions</div>
        <div className="center-col">
          <ul>
            {items.map((item, i) => (
              <li
                id={item}
                onClick={handleSuggestionClick}
                className="onboarding-container-item"
                key={`item_${i}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div >
  );
}

import "./NoteForm.css";
import React, { useState, useEffect, useContext } from "react";
import Notes from "./Notes";
import AddNote from "./AddNote";
import { AuthContext } from "../auth/AuthProvider";
import firebase from "../../config/constants";
import { UserContext } from "../../pages/Profile/HomePage";
const uuidv4 = require("uuid/v4");

export default function NoteForm(props) {
  const [currentCardId, setCurrentCardId] = useState("");
  const { profiles } = useContext(AuthContext);
  const key = profiles.document_key;
  const [newProfile, setProfile] = useState(profiles);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState([]);
  let { editMode } = useContext(UserContext);

  useEffect(() => {

    let map = {
      push_personal: "PERSONAL",
      push_professional: "PROFESSIONAL",
      pull_actionable: "QUICK ASKS",
      // pull_general: "GENERAL",
      pull_wild: "WILD REQUESTS",
      // tidBits: "If you knew the real me, you'd know that...🤫🥰",
      // myPushers: "People who've helped me ❤️"
    };
    setTitle(map[props.title]);
  }, []);

  useEffect(() => {
    setProfile(profiles);
    if (Object.keys(profiles).length > 0) {
      setNotes(profiles[props.title]);
    }
  }, [profiles]);

  const deleteNote = (id, notes) => {
    const newNotes = notes.filter(note => {
      return note.id !== id;
    });
    setNotes(newNotes);
    firebase
      .firestore()
      .collection("profiles")
      .doc(key)
      .update({
        [props.title]: newNotes || "meh"
      });
    setCurrentCardId("");
  };

  const changeEditMode = (id, notes) => {
    const newNotes = notes.map(note => {
      if (note.id === id) {
        note.isInEditMode = !note.isInEditMode;
      }
      return note;
    });
    setNotes(newNotes);
  };

  const addNote = async (note, notes) => {
    note.id = uuidv4();
    note.isInEditMode = false;
    note.createdDate = new Date();

    let newNotes = [...notes, note];

    setNotes(newNotes);
    firebase
      .firestore()
      .collection("profiles")
      .doc(key)
      .update({
        [props.title]: newNotes
      });
    let data = {
      user: profiles.document_key,
      title: note.title,
      action_type: "add",
      user_email: profiles.email,
      user_name: profiles.displayName,
      content: note.content,
      type: props.title,
      createdDate: new Date()
    };
    const ref = await firebase
      .firestore()
      .collection("pushes_pulls")
      .doc();
    ref.set({ ...data, document_key: ref.id });
  };

  const editNote = async (id, updatedTitle, updatedText, notes) => {
    let check = true;
    const newNotes = notes.map(note => {
      if (note.id === id) {
        if (updatedTitle != "") {
          note.title = updatedTitle;
        }
        if (updatedText !== "") {
          note.content = updatedText;
        }
        note.isInEditMode = !note.isInEditMode;

        if (note.content === undefined) {
          check = false;
          note.isInEditMode = !note.isInEditMode;
        }

        note.createdDate = new Date();

        let data = {
          user: profiles.document_key,
          title: note.title,
          action_type: "update",
          user_email: profiles.email,
          user_name: profiles.displayName,
          content: note.content,
          type: props.title,
          createdDate: new Date()
        };
        const ref = firebase
          .firestore()
          .collection("pushes_pulls")
          .doc();
        ref.set({ ...data, document_key: ref.id });
      }

      return note;
    });

    if (check) {
      setNotes(newNotes);
      firebase
        .firestore()
        .collection("profiles")
        .doc(key)
        .update({
          [props.title]: newNotes
        });
    } else {
      setNotes(newNotes);
    }
  };

  return (
    <div class="sub-category-section">
      {console.log("here are the props.title", props.title)}
      {!editMode ? (<h5 className="left-align sub-category" style={{ marginBottom: "16px" }}>{title}</h5>) : (
        (editMode || profiles[props.title] && profiles[props.title].length && props.title !== "pull_general") ? (
          <h5 className="left-align sub-category">{title}</h5>
        ) : null)
      }
      <div >
        {props.title !== "pull_general" ?
          <AddNote
            notes={notes}
            addNote={addNote}
            deleteNote={deleteNote}
            currentCardId={currentCardId}
          />
          : null}
        <Notes
          notes={notes}
          setCurrentCardId={setCurrentCardId}
          changeEditMode={changeEditMode}
          deleteNote={deleteNote}
          editNote={editNote}
          title={props.title}
        />
      </div>
    </div >
  );
}

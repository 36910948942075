import React from "react";
import { whitelisted_communities } from "./config";

export default function WelcomeCommunities(props) {
    const { values, sets } = props;

    return (

        <div className="onboarding-pushpull-community-notice">
            <h3>
                {console.log("hello")}
                Welcome to the {whitelisted_communities[values.community].name}{" "}
                PushPull community! 🎉
            </h3>
            <div style={{ marginBottom: 4 }}>
                List things you can help with (<b>pushes</b>) & things you’d like
                help with (<b>pulls</b>).
            </div>
            <div>
                Everything you list here will be private—only{" "}
                {whitelisted_communities[values.community].name} members will see
                it.
            </div>
        </div>
    );
}
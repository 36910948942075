import React from "react";
import ProgressBar from "../ProgressBar";

export default function FillOutProfileCard(props) {
  const { values } = props;

  const Next = async e => {
    e.preventDefault();
    console.log("button pressed");
    props.nextStep();
  };

  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };

  return (
    <div>
      <div className="profile-helper-box-title collection">
        How to get the most out of your PushPull Card in 3 simple steps!
      </div>
      <div className="profile-helper-box collection">
        <ProgressBar percent={values.percent} />
        <div style={{ clear: "both" }}></div>
        <div className="profile-helper-box-content">
          <div className="arrow-container">
            <button className="arrow arrow-left" onClick={Previous}></button>{" "}
          </div>
          <div className="profile-helper-box-text">
            Step 2: Fill out the rest of your PushPull profile<br></br>
            <br></br>
            People with 3 pushes and 3 pulls are 3x more likely to get their
            pushes pulled <a href={"/user/max"}>example PushPull card</a>
            <div className="profile-helper-box-subtext">
              Your PushPull is viewable and shareable at:{" "}
              {values.handle ? (
                <a href={`/user/${values.handle}`}>
                  pushpull.community/user/{values.handle}
                </a>
              ) : (
                  ""
                )}{" "}
            </div>
          </div>
          <div className="arrow-container">
            <button className="arrow arrow-right" onClick={Next}></button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import UserForm from './onboarding/UserForm';

const Tracker = styled.div`
    width: 100px;
    height: 16px;
    background-color: #ececec;
    border-radius: 8px;
    float: right;
`;

const Thumb = styled.div`
    width: ${props => props.percentage}%;
    background-color: #2f3a56;
    height: 100%;
    border-radius: 8px;
    float: left

`;

export default function ProgressBar(props) {
    return (
        <div>
            <Tracker>
                <Thumb percentage={props.percent} />
            </Tracker>
        </div>
    )
}
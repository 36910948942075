import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { AuthContext } from "../auth/AuthProvider";
import { Emoji } from "emoji-mart";
import firebase, { firebaseAuth } from "../../config/constants";
import ProfileModal from "../modal/ProfileModal";
import defaultProfilePicture from "../../images/defaultProfilePicture.png";
import { UserContext } from "../../pages/Profile/HomePage";
import HeaderCards from "./HeaderCards";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { uploadImage } from "../../utils/firebase/uploadImage";
import "firebase/storage";
import { FRONTEND_HOST } from "../../config/frontEndHost";

import "./Header.css";
import ProfessionalPush from "../onboarding/ProfessionalPush";

export default function Header() {
  let { profiles, setProfiles, loggedInHandle } = useContext(AuthContext);
  let currentUser = firebaseAuth().currentUser;

  const [loggedInUser, setLoggedInUser] = useState("");
  const [newProfile, setProfile] = useState(profiles);
  const [userHandle, setHandle] = useState("");
  const [headline, setHeadline] = useState("");
  const [headerIntro, setHeaderIntro] = useState("");
  const [occupation, setOccupation] = useState("");
  const [location, setLocation] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [emoji, setEmoji] = useState("grinning");
  const [profilePhoto, setProfilePhoto] = useState("");
  // const [loggedInHandle, setLoggedInHandle] = useState("");
  let { editMode, userHandleParam } = useContext(UserContext);

  const [copiedText, setCopiedText] = useState("");
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    console.log(`${FRONTEND_HOST}/user/${profiles.userHandle}`);
    setCopiedText(`${FRONTEND_HOST}/user/${profiles.userHandle}`);
  };
  // const formatLinkedInUrl = () => {
  //   console.log("hello i am called!")
  //   let userInput = profiles.linkedin;
  //   if (userInput) {
  //     if (userInput.startsWith("www")) {
  //       setLinkedin("http://" + userInput)
  //     } else if (userInput.startsWith("http")) {
  //       //do nothing
  //     } else if (userInput.startsWith("linkedin")) {
  //       setLinkedin("http://www." + userInput)
  //     }
  //     else {
  //       //must be just their handle
  //       setLinkedin("http://www.linkedin.com/in/" + userInput)
  //     }
  //   }
  // }
  // const formatTwitterHandle = () => {
  //   console.log("hi im formatting the handle")
  //   let userInput = profiles.twitter;
  //   if (userInput) {
  //     if (userInput.startsWith("www")) {
  //       setTwitter("http://" + userInput)
  //     } else if (userInput.startsWith("http")) {
  //       //do nothing
  //     } else if (userInput.startsWith("@")) {
  //       userInput = userInput.substring(1)
  //       console.log("here's the userInput", userInput)
  //       setTwitter("http://www.twitter.com/" + userInput)
  //     } else {
  //       //must be just their handle
  //       setTwitter("http://www.twitter.com/" + userInput)
  //     }
  //   }
  // }
  // const formatInstagramHandle = () => {
  //   let userInput = profiles.instagram;
  //   if (userInput) {
  //     if (userInput.startsWith("www")) {
  //       setInstagram("http://" + userInput)
  //     } else if (userInput.startsWith("http")) {
  //       //do nothing
  //     } else if (userInput.startsWith("@")) {
  //       userInput = userInput.substring(1)
  //       setInstagram("http://www.instagram.com/" + userInput)
  //     } else {
  //       //must be just their handle
  //       setInstagram("http://www.instagram.com" + userInput)
  //     }
  //   }
  // }

  useEffect(() => {
    if (!(currentUser === null)) {
      if (profiles.uid === currentUser.uid) {
        setLoggedInUser(profiles);
      }
    }
    // formatLinkedInUrl();
    // formatTwitterHandle();
    // formatInstagramHandle();

    setProfile(profiles);
    setHandle(profiles.userHandle);
    setHeadline(profiles.headline);
    setHeaderIntro(profiles.headerIntro);
    setLocation(profiles.location);
    setOccupation(profiles.occupation);
    setLinkedin(profiles.linkedin);
    setTwitter(profiles.twitter);
    setInstagram(profiles.instagram);
    setEmoji(profiles.emoji);
    setProfilePhoto(profiles.photoURL);
  }, [profiles]);

  const displayName = profiles.displayName;

  const routePublicPushPull = () => {
    let currentUser = firebaseAuth().currentUser;
    firebase
      .firestore()
      .collection("profiles")
      .where("uid", "==", currentUser.uid)
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          let handle = doc.data().userHandle;
          window.location.assign(`/user/${handle}`);
          return;
        });
      });
    return;
  };

  const handleImageChange = async event => {
    const image = await event.target.files[0];
    const formData = new FormData();
    await formData.append("image", image);
    await formData.append("name", image.name);
    uploadImage(
      formData,
      setProfilePhoto,
      profiles.document_key,
      profiles.email
    );

    //send to the server
  };

  const handleEditPicture = event => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  //check if you're on your own page

  return (
    <div>
      <div style={{ marginRight: "10%" }}>
        {!editMode && userHandleParam === loggedInHandle ? (
          <button
            onClick={() => window.location.assign("/edit")}
            className="pushpull-btn right"
          >
            Edit Profile
          </button>
        ) : editMode ? (
          <button
            onClick={() => routePublicPushPull()}
            className="pushpull-btn right"
          >
            View Public Profile
          </button>
        ) : null}
      </div>
      <div>
        <div className="profile-header-edit">
          <div className="row">
            <div className="col s12 m3 l3" style={{ position: "relative" }}>
              <img
                className="profile-header-photo profile-image"
                src={profilePhoto ? profilePhoto : defaultProfilePicture}
              />
              {editMode ? (
                <div>
                  {" "}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="imageInput"
                    onChange={handleImageChange}
                  />
                  <IconButton
                    onClick={handleEditPicture}
                    className="button"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: "15%",
                      background: "white"
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </div>
              ) : null}
            </div>
            <div className="col s12 m12 l8">
              <div className="left-align" style={{ marginBottom: -20 }}>
                <h4 className="displayName" style={{ fontSize: 32 }}>
                  {displayName}
                </h4>
              </div>
              <div
                className="left-align"
                style={{ marginTop: 20, fontFamily: "Raleway" }}
              >
                <h4>{userHandle ? `@${userHandle}` : ""}</h4>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="left-align"
              >
                <h6
                  style={{
                    fontWeight: "normal",
                    fontFamily: "Raleway",
                    fontSize: 18,
                    fontColor: "#110f24"
                  }}
                >
                  My spirit emoji is{" "}
                </h6>
                {/* <button
                  onClick={() => {
                    axios
                      .get(
                        `http://localhost:5001/warmly-dev/us-central1/sendMail?dest=alan.zhao908@gmail.com`
                      )
                      .then(result => console.log("welcome email", result))
                      .catch(err => console.log(err));
                  }}
                >
                  Click me
                </button> */}
                <div style={{ marginLeft: 10 }}>
                  <Emoji
                    emoji={emoji || "grinning"}
                    set="messenger"
                    size={30}
                  />
                </div>
              </div>
              <div
                className="left-align profile-header-line"
              // style={{ marginTop: 24 }}
              >
                <span style={{ fontFamily: "Raleway", fontSize: 18 }}>
                  I'm a
                </span>
                <img className="icon" src={require("../../images/work.png")} />
                <span
                  style={{
                    fontFamily: "Raleway",
                    fontSize: 18
                  }}
                >
                  {occupation}
                </span>
              </div>
              {location ? (
                <div
                  className="left-align profile-header-line"
                  style={{ marginTop: 8 }}
                >
                  <span style={{ fontFamily: "Raleway", fontSize: 18 }}>
                    I'm currently located in
                </span>
                  <img
                    className="icon"
                    src={require("../../images/location.png")}
                  />
                  <span style={{ fontFamily: "Raleway", fontSize: 18 }}>
                    {location}
                  </span>
                </div>
              ) : null}
              <div
                className="left-align profile-header-line"
                style={{ marginTop: 8 }}
              >
                {linkedin ? (
                  <a
                    style={{ display: "inline" }}
                    target="_blank"
                    href={linkedin}
                  >
                    <img
                      className="icon"
                      src={require("../../images/linkedin-icon.svg")}
                    />
                  </a>
                ) : null}
                {twitter ? (
                  <a
                    style={{ display: "inline" }}
                    target="_blank"
                    href={twitter}
                  >
                    <img
                      className="icon"
                      src={require("../../images/twitter-icon.svg")}
                    />
                  </a>
                ) : null}
                {instagram ? (
                  <a
                    style={{ display: "inline" }}
                    target="_blank"
                    href={instagram}
                  >
                    <img
                      className="icon"
                      src={require("../../images/insta-icon.png")}
                    />
                  </a>
                ) : null}
              </div>
              <div className="share-container">
                <div className="privacy">Share profile:</div>
                <div className="share-url">
                  <CopyToClipboard onCopy={onCopy} text={copiedText}>
                    <button className="pushpull-btn secondary">
                      <i className="fa fa-link"></i> Copy Link
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <div className="col s12 m3 l1" style={{ marginTop: 28 }}>
              <ProfileModal
                userHandle={userHandle}
                headline={headline}
                headerIntro={headerIntro}
                occupation={occupation}
                location={location}
                linkedin={linkedin}
                twitter={twitter}
                instagram={instagram}
                emoji={emoji}
                setHandle={setHandle}
                setHeadline={setHeadline}
                setHeaderIntro={setHeaderIntro}
                setOccupation={setOccupation}
                setLinkedin={setLinkedin}
                setTwitter={setTwitter}
                setInstagram={setInstagram}
                setLocation={setLocation}
                setEmoji={setEmoji}
              />
            </div>
            {/* {editMode ? null : (
              <a
                target="_blank"
                className="pushpull-btn right"
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${
                  profiles.email
                  }&su=Introduction&body=Hi ${
                  profiles.displayName
                    ? profiles.displayName
                      .split(" ")
                      .slice(0, -1)
                      .join(" ")
                    : ""
                  },%0D%0A%0D%0AI saw your PushPull profile at https://pushpull.community/user/${
                  profiles.userHandle
                  } and would love to talk to you sometime when you're free! 
                ${
                  currentUser === null
                    ? ``
                    : `%0D%0A%0D%0AHere's my PushPull card: https://pushpull.community/user/${loggedInUser.userHandle}`
                  } %0D%0A%0D%0AWarmly,%0D%0A${
                  currentUser === null ? `` : loggedInUser.displayName
                  }
                `}
              >
                Contact me
              </a>
            )} */}
          </div>
        </div>
        {editMode ? (
          <HeaderCards userHandle={userHandle} profiles={profiles} />
        ) : null}
      </div>
    </div>
  );
}

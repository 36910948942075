import React, { useState, useContext, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { validatePull } from "../../schemas/onboarding";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";
import { whitelisted_communities } from "./config";

const items = [
  "Advice on Sales",
  "Getting started with real estate investing",
  "Connections to a wedding shop",
  "Intros to DC staffers",
  "Help getting into motorcycles",
  "Cooking Apple Pie"
];

export default function Pull(props) {
  const { profiles } = useContext(AuthContext);
  const [handleError, setHandleError] = useState("");

  useEffect(() => {
    const setLastOnboarding = async () => {
      if (!(Object.keys(profiles).length === 0)) {
        await console.log("part 4", profiles);
        const ref = await firebase
          .firestore()
          .collection("profiles")
          .doc(profiles.document_key);
        ref.get().then(doc => {
          let profile = doc.data();
          if (!profile.hasOwnProperty("onboarding_4_pull")) {
            let data = {
              last_onboarding_step: "onboarding_4_pull"
            };
            ref.set(data, { merge: true });
          }
        });
      }
    };
    setTimeout(() => {
      setLastOnboarding();
    }, 100);
  }, [profiles]);

  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };
  const Next = async e => {
    e.preventDefault();
    try {
      const isValid = await validatePull.validate(
        {
          pullTitle: values.pullTitle,
          pullContent: values.pullContent
        },
        {
          abortEarly: false
        }
      );

      if (!isValid) {
        return;
      }
    } catch (err) {
      console.log(err.errors[0]);
      setHandleError(err.errors[0]);
      return;
    }

    const ref = await firebase
      .firestore()
      .collection("profiles")
      .doc(profiles.document_key);

    ref.get().then(doc => {
      let profile = doc.data();
      if (!profile.hasOwnProperty("onboarding_4_pull")) {
        let data = {
          onboarding_4_pull: new Date()
        };
        ref.set(data, { merge: true });
      }
    });

    props.nextStep();
  };

  const handleSuggestionClick = async e => {
    await sets.setPullTitle(e.target.id);
  };

  const { values, sets } = props;

  return (
    <div>
      <div className="onboarding-progress-bar">
        <ProgressBar percent={values.percent} />
        {values.community in whitelisted_communities ? (
          < div >
            <img
              className="onboarding-community-logo"
              src={whitelisted_communities[values.community].logo}
            />
          </ div>
        ) : null}
        <div style={{ clear: "both" }}></div>
      </div>
      <form>
        <h4 className="onboarding-card-title">
          <div className="onboarding-card-category">🙌Now, add a Pull: </div>
          <div>What's something you would like <span className="onboarding-card-accent"> help </span> with
            {values.community in whitelisted_communities
              ? " from people at " + whitelisted_communities[values.community].name
              : null}
            ?
          </div>
          {values.community in whitelisted_communities ? (
            <div className="onboarding-pushpull-privacy">
              <i className="fa fa-lock"></i> Only visible to{" "}
              {whitelisted_communities[values.community].name}
            </div>
          ) : null}
        </h4>
        <input
          autoFocus="autofocus"
          id="pullTitle"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder={
            values.community in whitelisted_communities ? whitelisted_communities[values.community].pull_professional.title :
              "Management Skills"
          }
          onChange={e => {
            sets.setPullTitle(e.currentTarget.value);
          }}
          value={values.pullTitle}
        />

        <br />
        <textarea
          id="pullContent"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder={
            values.community in whitelisted_communities ? whitelisted_communities[values.community].pull_professional.description :
              "I'm want to be an effective leader within my team and would love some advice on how to make informative decisions and manage ambiguity in the workplace."
          }
          onChange={e => {
            sets.setPullContent(e.currentTarget.value);
          }}
          value={values.pullContent}
        />
        <div className="handle-error-text">{handleError}</div>

        <div class="button-bar">
          <button
            onClick={Next}
            className="pushpull-btn right"
            type="submit"
            name="action"
          >
            Continue
          </button>
          <button
            onClick={Previous}
            className="pushpull-btn secondary left"
            type="submit"
            name="action"
          >
            Back
          </button>
        </div>
      </form>
      <div className="onboarding-container">
        <div>Stuck? Build off of one of our suggestions</div>
        <div className="center-col">
          <ul>
            {items.map((item, i) => (
              <li
                id={item}
                onClick={handleSuggestionClick}
                className="onboarding-container-item"
                key={`item_${i}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import ReactModal from "react-modal";
import defaultProfilePicture from "../../images/defaultProfilePicture.png";
import firebase, { firebaseAuth } from "../../config/constants";
import { Link } from "react-router-dom";
import "./NavBar.css";
import { AuthContext } from "../auth/AuthProvider";
import { getUA } from "react-device-detect";
import { whitelisted_communities } from "../onboarding/config";

export default function NavBar(props) {
  const { profiles, currentUser } = useContext(AuthContext);
  const [communitiesForCurrentUser, setCommunitiesForCurrentUser] = useState(
    []
  );
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [showBetaModal, setShowBetaModal] = useState(false);
  const [communitySuggestions, setCommunitySuggestions] = useState("");
  const [photoURL, setPhotoURL] = useState("");

  useEffect(() => {
    if (profiles.communities) {
      setCommunitiesForCurrentUser(profiles.communities);
    }
  });

  useEffect(() => {
    if (currentUser) {
      if ("profile" in currentUser) {
        setPhotoURL(currentUser.profile.photoURL);
      }
    }
  }, [currentUser]);

  const onCommunityApplications = (e) => {
    setShowBetaModal(false);

    if (communitySuggestions.length == 0) {
      return;
    }

    if (communitySuggestions in whitelisted_communities) {
      let key = profiles.document_key;
      firebase
        .firestore()
        .collection("profiles")
        .doc(key)
        .set({ communities: [communitySuggestions] }, { merge: true })
        .then((result) => {
          window.location.assign("/communities");
        });
    } else {
      let community_suggestions = {
        createdDate: new Date(),
        communitySuggestions: communitySuggestions,
        displayName: currentUser.displayName,
        email: currentUser.email,
        source: "navbar",
      };

      firebase
        .firestore()
        .collection("community_suggestions")
        .doc(communitySuggestions)
        .set({ ...community_suggestions });
    }
  };

  const onSendFeedback = (e) => {
    e.preventDefault();
    let currentUser = firebaseAuth().currentUser;
    console.log("here's the current user:", currentUser);

    let feedback_data = {
      createdDate: new Date(),
      feedback: feedback,
      displayName: currentUser.displayName,
      email: currentUser.email,
      userAgent: userAgent,
    };
    const ref = firebase.firestore().collection("feedback").doc();
    ref.set({ ...feedback_data });

    setShowFeedbackModal(false);
  };

  const routePublicPushPull = () => {
    window.location.assign(`/edit`);
  };

  const logout = () => {
    firebaseAuth()
      .signOut()
      .then(function () {
        window.location.assign("/explore");
      });
  };

  let loggedIn = false;

  if (currentUser) {
    loggedIn = true;
  }

  return (
    <div>
      <div
        style={{ backgroundColor: "#426E93" }}
        class="navbar navbar-default navbar-fixed-top"
        role="navigation"
      >
        <a
          href="/home.html"
          style={{
            color: "white",
            marginLeft: 10,
            marginRight: 20,
            marginTop: 5,
          }}
          class="navbar-brand"
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <img
                style={{ height: 60, width: 60 }}
                src={require("../../images/PushPullLogo.png")}
              />
            </div>
            <div
              className="navbar-tagline"
              style={{ marginLeft: 10, marginTop: 10 }}
            >
              <div className="navbar-tagline" href="#">
                PushPull
              </div>
              <div className="navbar-tagline-subtext">
                Ask Intentionally & Give Warmly,
              </div>
            </div>
          </div>
        </a>

        <div class="container" style={{ width: "90%" }}>
          <div class="navbar-header" style={{ minHeight: 80 }}>
            <button
              style={{ marginTop: 20, marginRight: 20 }}
              class="navbar-toggle"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span
                style={{ backgroundColor: "white" }}
                class="icon-bar"
              ></span>
              <span
                style={{ backgroundColor: "white" }}
                class="icon-bar"
              ></span>
              <span
                style={{ backgroundColor: "white" }}
                class="icon-bar"
              ></span>
            </button>
          </div>
          <div class="navbar-collapse collapse">
            {loggedIn ? (
              <div>
                <ul
                  class="nav navbar-nav navbar-left"
                  style={{ marginTop: 10 }}
                ></ul>
                <ul
                  class="nav navbar-nav navbar-right"
                  style={{ marginTop: 13 }}
                >
                  <div className="pages-container">
                    <li className="pages-list nav-bar-item">
                      <Link
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontFamily: "Raleway",
                          fontSize: 15,
                        }}
                        onClick={() => window.location.assign("/explore")}
                      >
                        Explore
                      </Link>
                    </li>
                    <li
                      className="pages-list nav-bar-item"
                      style={{ position: "relative" }}
                    >
                      <span className="beta-tag">beta</span>
                      {communitiesForCurrentUser.length > 0 ? (
                        <Link
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontFamily: "Raleway",
                            fontSize: 15,
                          }}
                          onClick={() => {
                            window.location.assign("/communities");
                          }}
                        >
                          Communities
                        </Link>
                      ) : (
                        <div>
                          <Link
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontFamily: "Raleway",
                              fontSize: 15,
                            }}
                            onClick={() => {
                              setShowBetaModal(true);
                            }}
                          >
                            Communities
                          </Link>
                          <ReactModal
                            isOpen={showBetaModal}
                            contentLabel="Minimal Modal Example"
                            className="Modal"
                            ariaHideApp={false}
                            shou
                            fldCloseOnOverlayClick={false}
                            overlayClassName="Overlay"
                            onRequestClose={() => setShowBetaModal(false)}
                            style={{
                              width: "80%",
                              justifyContent: "center",
                              marginRight: 0,
                            }}
                          >
                            <form>
                              <h4
                                style={{
                                  marginBottom: "20px",
                                  color: "#2f3a56",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                }}
                              >
                                🥳 You found our beta feature! Communities are
                                invite only right now. Enter your invite code
                                here. Or enter the name of the organization /
                                team you'd like to create a community for.
                              </h4>
                              <label
                                for="communitySuggestions"
                                style={{
                                  fontSize: 15,
                                  display: "block",
                                }}
                              >
                                Community
                              </label>
                              <input
                                id="communitySuggestions"
                                type="text"
                                style={{
                                  color: "#2f3a56",
                                  width: "100%",
                                }}
                                placeholder="INVITECODE"
                                onChange={(e) => {
                                  setCommunitySuggestions(
                                    e.currentTarget.value
                                  );
                                  setUserAgent(getUA);
                                }}
                              ></input>
                              <div className="button-bar">
                                <button
                                  onClick={onCommunityApplications}
                                  className="pushpull-btn right"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </ReactModal>
                        </div>
                      )}
                    </li>
                    <li className="pages-list nav-bar-item">
                      <Link
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontFamily: "Raleway",
                          fontSize: 15,
                        }}
                        onClick={logout}
                      >
                        Logout
                      </Link>
                    </li>
                    <li className="pages-list">
                      <button
                        className="feedback-btn"
                        onClick={() => setShowFeedbackModal(true)}
                      >
                        Send Feedback
                      </button>
                      <ReactModal
                        isOpen={showFeedbackModal}
                        contentLabel="Minimal Modal Example"
                        className="Modal"
                        ariaHideApp={false}
                        shou
                        fldCloseOnOverlayClick={false}
                        overlayClassName="Overlay"
                        onRequestClose={() => setShowFeedbackModal(false)}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          marginRight: 0,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <form>
                            <h4
                              style={{
                                marginBottom: 0,
                                color: "#2f3a56",
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Send Feedback!
                            </h4>
                            <h1
                              style={{
                                marginTop: 1,
                                color: "#2f3a56",
                                fontSize: 12,
                                fontStyle: "italic",
                                width: "400px",
                              }}
                            >
                              Let us know about any bugs, features you would
                              love to see, things that didn't make sense, or
                              things that you love!
                            </h1>
                            <textarea
                              id="feedback"
                              style={{
                                color: "#2f3a56",
                                width: "400px",
                              }}
                              placeholder="Hey PushPullers! Thanks for your feedback, we'll get right back to you! :)"
                              onChange={(e) => {
                                setFeedback(e.currentTarget.value);
                                console.log("here's the userAgent", getUA);
                                setUserAgent(getUA);
                              }}
                            ></textarea>
                            <div className="button-bar">
                              <button
                                onClick={onSendFeedback}
                                type="submit"
                                className="pushpull-btn right"
                              >
                                Send
                              </button>
                            </div>
                          </form>
                        </div>
                      </ReactModal>
                    </li>
                    <li className="disabled">
                      <img
                        className="profile-image"
                        style={{
                          marginLeft: 20,
                          width: 50,
                          height: 50,
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        src={photoURL ? photoURL : defaultProfilePicture}
                        onClick={() => routePublicPushPull()}
                      />
                    </li>
                  </div>
                </ul>
              </div>
            ) : (
              <div>
                <ul
                  class="nav navbar-nav navbar-left"
                  style={{ marginTop: 13 }}
                ></ul>
                <ul
                  class="nav navbar-nav navbar-right"
                  style={{ marginTop: 13 }}
                >
                  <li className="pages-list nav-bar-item">
                    <Link
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Raleway",
                        fontSize: 15,
                      }}
                      onClick={() => window.location.assign("/explore")}
                    >
                      Explore
                    </Link>
                  </li>
                  <li>
                    <div
                      style={{
                        marginTop: 4,
                        backgroundColor: "#e2474b",
                        borderRadius: 8,
                        padding: "12px 32px",
                        textAlign: "center",
                        fontSize: 16,
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Raleway",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location.assign("/login");
                      }}
                    >
                      Create your card
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

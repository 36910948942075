import React from "react";
import NoteForm from "../../components/profile/NoteForm";
import { firebaseAuth } from "../../config/constants";
import { Card } from "react-bootstrap";
import Header from "../../components/profile/Header";
import "./ProfilePage.css";

export default function ProfilePage() {
  let loggedIn = false;
  let currentUser = firebaseAuth().currentUser;
  if (currentUser) {
    loggedIn = true;
  }

  return (
    <div>
      <Header />
      <div style={{ margin: "20px 15%" }}>
        <div className={"row "}>
          <div className="col s12 m6">
            <div className="left-align profile-category">🎁 Push</div>
            <div className="left-align profile-category-sub">
              I would love to help you with
            </div>
            <div style={{ margin: 20 }} />
            <NoteForm title={"push_professional"} />
            <NoteForm title={"push_personal"} />
          </div>
          <div className="col s12 m6">
            <div
              className="left-align profile-category"
            // style={{ marginLeft: "12%" }}
            >
              🙌 Pull
            </div>
            <div className="left-align profile-category-sub">
              I would like some help with
            </div>
            <div style={{ margin: 20 }} />
            <NoteForm title={"pull_actionable"} />
            <NoteForm title={"pull_general"} />
            <NoteForm title={"pull_wild"} />
          </div>
        </div>
        <div
          className={
            "row " + (!loggedIn ? "real-talk-see-more-container" : null)
          }
        >
          {!loggedIn ? (
            <div className="profile-see-more-wrapper">
              <div className="profile-blur-container"></div>
              <div className="profile-see-more-container ">
                <div className="profile-see-more">
                  To see more, create a PushPull card 🥳
                  <div className="profile-see-more-subtext">
                    Build more meaningful relationships and showcase your
                    authentic personality
                  </div>
                  <div className="three-part-win-container">
                    <div className="win-item-container">
                      <div className="win-item-title">2.7 minutes</div>
                      <div className="win-item-content">
                        or less to make a card
                      </div>
                    </div>
                    <div className="win-item-container">
                      <div className="win-item-title">2 needs met</div>
                      <div className="win-item-content">per week</div>
                    </div>
                    <div className="win-item-container">
                      <div className="win-item-title">250% more</div>
                      <div className="win-item-content">
                        community engagement
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="profile-create-card"
                  onClick={() => {
                    window.location.assign("/login");
                  }}
                >
                  Create your card
                </div>
              </div>
            </div>
          ) : null}
          <div className="col s12 m6">
            <div className="left-align profile-category">Tidbits</div>
            <div className="left-align profile-category-sub">
              Little known facts about me are
            </div>
            <div style={{ margin: 20 }} />
            <NoteForm title={"tidBits"} />
          </div>
          <div className="col s12 m6">
            <div className="left-align profile-category">Helpers</div>
            <div className="left-align profile-category-sub">
              My gratitude goes out to
            </div>
            <div className="left-align profile-category-subtext">
              To tag a pusher type "@[pusher's URL handle]" in the edit box
            </div>
            <div style={{ margin: 20 }} />
            <NoteForm title={"myPushers"} />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import firebase, { firebaseAuth } from "../../config/constants";
import axios from "axios";
import { HOST_FIREBASE } from "../../config/firebaseHost";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState({});
  const [userHandles, setUserHandles] = useState({});
  const [loggedInHandle, setLoggedInHandle] = useState("");

  useEffect(() => {
    firebaseAuth().onAuthStateChanged(user => {
      let currUser = user;
      setCurrentUser(user);
      setLoading(false);
      console.log("user", user);
      const fetchData = async () => {
        if (user) {
          await firebase
            .firestore()
            .collection("profiles")
            .where("uid", "==", user.uid)
            .get()
            .then(async snapshot => {
              //create new user
              if (snapshot.docs.length === 0 && user.emailVerified === true) {
                let data = {
                  createdDate: new Date(),
                  displayName: user.displayName,
                  email: user.email,
                  photoURL: user.photoURL,
                  headerIntro: "",
                  headline: "",
                  myPushers: [],
                  pull_actionable: [],
                  pull_general: [],
                  pull_wild: [],
                  push_personal: [],
                  push_professional: [],
                  tidBits: [],
                  occupation: "",
                  location: "",
                  uid: user.uid,
                  emoji: "",
                  onboardingCompleted: false,
                  userHandle: user.displayName
                    .split(" ")
                    .join("-")
                    .toLowerCase()
                    .concat(`-${Math.floor(Math.random() * 90 + 10)}`)
                };
                const ref = await firebase
                  .firestore()
                  .collection("profiles")
                  .doc();
                ref
                  .set({
                    ...data,
                    document_key: ref.id
                  })
                  .then(_ref => {
                    setProfiles({
                      ...data,
                      document_key: ref.id
                    });

                    setLoading(false);
                  });

                firebase
                  .firestore()
                  .collection("userHandles")
                  .doc(data.userHandle)
                  .set({
                    email: user.email,
                    userHandle: data.userHandle,
                    user: ref.id,
                    createdDate: new Date()
                  });
                console.log("user handle created");
                console.log("email", user.email);
                // axios
                //   .get(`${HOST_FIREBASE}/sendMail?dest=${user.email}`)
                //   .then(result => console.log("welcome email", result))
                //   .catch(err => console.log(err));
              } else {
                //existing user so return this user's data from firebase
                snapshot.docs.forEach(doc => {
                  let profileData = doc.data();
                  setProfiles(profileData);
                  firebase
                    .firestore()
                    .collection("profiles")
                    .where("uid", "==", user.uid)
                    .get()
                    .then(snapshot => {
                      snapshot.docs.forEach(doc => {
                        let userDoc = doc.data();
                        setCurrentUser({ ...currUser, profile: userDoc });
                      });
                    });
                  setLoading(false);
                });
              }
            });
        }
      };
      fetchData();
    });
  }, []);

  // used to pass down logged-in user's handle to check whether on own page
  if (currentUser) {
    firebase
      .firestore()
      .collection("profiles")
      .where("uid", "==", currentUser.uid)
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          let userDoc = doc.data();
          let handle = userDoc.userHandle;
          setLoggedInHandle(handle);
          return;
        });
      });
  }

  return loading ? (
    <div> </div>
  ) : (
      <AuthContext.Provider
        value={{
          currentUser,
          profiles,
          setProfiles,
          loggedInHandle
        }}
      >
        {" "}
        {children}{" "}
      </AuthContext.Provider>
    );
};

import "firebase/storage";
import firebase from "../../config/constants";

export const uploadImage = (formData, setProfilePhoto, document_key, email) => {
  const storage = firebase.storage();
  var storageRef = storage.ref();
  var imagesRef = storageRef.child(`images/${email}_${document_key}`);

  imagesRef
    .put(formData.get("image"))
    .then(function(snapshot) {
      console.log("Uploaded a blob or file!");
      return snapshot.metadata.ref.getDownloadURL();
    })
    .then(url => {
      setProfilePhoto(url);
      firebase
        .firestore()
        .collection("profiles")
        .doc(document_key)
        .update({
          photoURL: url
        });
    });
};

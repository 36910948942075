import React, { useState, useEffect, useContext } from "react";
import firebase, { firebaseAuth } from "../../config/constants";
import PushPullListings from "../../components/explore/PushPullListings";
import NavBar from "../../components/navbar/NavBar";
import { AuthContext } from "../../components/auth/AuthProvider";
import ExploreFeed from "./ExploreFeed";

import "./ExplorePage.css";

export const UserContext = React.createContext();

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export default function ExplorePage(props) {
  const [loading, setLoading] = useState(false);
  const [allActivity, setAllActivity] = useState([]);
  const [users, setUsers] = useState([]);
  const [activityQuery, setActivityQuery] = useState([]);
  const [aQuery, setAQuery] = useState("");
  const [pushes, setPushes] = useState([]);
  const [pulls, setPulls] = useState([]);

  let { profiles, setProfiles } = useContext(AuthContext);
  let userHandleParam = props.match.params.userHandleParam || "";

  let editMode = true;
  let loggedIn = false;
  let currentUser = firebaseAuth().currentUser;
  if (currentUser) {
    loggedIn = true;
  }
  if (userHandleParam !== "") {
    editMode = false;
  }

  /**
   * Returns a push/pull activty object with relevant data to be shown in Activity Feed.
   * @param {*} pushpull Push/Pull from database.
   * @param {*} user Profile from database.
   * @return {*} Push/Pull activity object.
   */
  const generatePushPullActivityObject = (pushpull, pushpullType, user) => {
    let c = {};
    c.user = {};

    c.user.id = user.document_key;
    c.user.email = user.email;
    c.user.displayName = user.displayName;
    c.user.occupation = user.occupation;
    c.user.location = user.location;
    c.user.photoURL = user.photoURL;
    c.user.handle = user.userHandle;

    c.id = pushpull.id;
    c.createdDate = pushpull.createdDate;
    c.content = pushpull.content;
    c.title = pushpull.title;
    c.typeId = pushpullType;
    switch (pushpullType) {
      case "push_personal":
        c.category = "PERSONAL";
        c.type = "PUSH";
        break;
      case "push_professional":
        c.category = "PROFESSIONAL";
        c.type = "PUSH";
        break;
      case "pull_actionable":
        c.category = "ACTIONABLE";
        c.type = "PULL";
        break;
      case "pull_general":
        c.category = "GENERAL";
        c.type = "PULL";
        break;
      case "pull_wild":
        c.category = "WILD";
        c.type = "PULL";
        break;
    }

    return c;
  };

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("profiles")
        .get();
      let users = [];
      snapshot.docs.map(doc => {
        let user = doc.data();

        users.push(user);
      });
      setUsers(users);
      let pushes = [];
      let pulls = [];
      let all = [];

      users.map(user => {
        if (!user.communities) {
          for (let push of user.push_personal) {
            let c = generatePushPullActivityObject(push, "push_personal", user);
            pushes.push(c);
            all.push(c);
          }
          for (let push of user.push_professional) {
            let c = generatePushPullActivityObject(
              push,
              "push_professional",
              user
            );
            pushes.push(c);
            all.push(c);
          }
          for (let pull of user.pull_actionable) {
            let c = generatePushPullActivityObject(
              pull,
              "pull_actionable",
              user
            );
            pulls.push(c);
            all.push(c);
          }
          for (let pull of user.pull_general) {
            let c = generatePushPullActivityObject(pull, "pull_general", user);
            pulls.push(c);
            all.push(c);
          }
          for (let pull of user.pull_wild) {
            let c = generatePushPullActivityObject(pull, "pull_wild", user);
            pulls.push(c);
            all.push(c);
          }
        } else {
          // console.log("this user belongs to a community", user);
        }
      });

      // const all_sorted = all.sort(function (a, b) {
      //   if (a.createdDate) {
      //     return b.createdDate.toDate() - a.createdDate.toDate();
      //   } else {
      //     return a.createdDate.toDate() - b.createdDate.toDate();
      //   }
      // });

      setAllActivity(all);
      setPushes(shuffle(pushes));
      setPulls(shuffle(pulls));
      setActivityQuery(shuffle(all));
    };
    fetchData();
  }, []);

  const loggedOutView = () => {
    console.log("user is logged out");
  };

  function toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return String(t);
  }

  const activitySearch = async e => {
    let query = e.currentTarget.value;

    setAQuery(query);
    let newActivities = [];
    let newPushes = [];
    let newPulls = [];

    for (let item of allActivity) {
      if (
        (item.user.displayName &&
          item.user.displayName.toLowerCase().includes(query.toLowerCase())) ||
        (item.user.occupation &&
          item.user.occupation.toLowerCase().includes(query.toLowerCase())) ||
        (item.user.location &&
          item.user.location.toLowerCase().includes(query.toLowerCase())) ||
        (item.content &&
          item.content.toLowerCase().includes(query.toLowerCase())) ||
        (item.title && item.title.toLowerCase().includes(query.toLowerCase()))
      ) {
        newActivities.push(item);
        if (item.type === "PUSH") {
          newPushes.push(item);
        } else {
          newPulls.push(item);
        }
      }
    }

    setPushes(newPushes);
    setPulls(newPulls);
    setActivityQuery(newActivities);
  };

  return loading ? null : (
    <div>
      <UserContext.Provider value={{ editMode: editMode }}>
        {loggedIn ? (
          <ExploreFeed
            pushes={pushes}
            pulls={pulls}
            allActivity={activityQuery}
            activitySearch={activitySearch}
            aQuery={aQuery}
          />
        ) : (
            <div className="logged-out-container">
              <ExploreFeed
                pushes={pushes}
                pulls={pulls}
                allActivity={activityQuery}
                activitySearch={activitySearch}
                aQuery={aQuery}
              />
              <div className="see-more-wrapper">
                <div className="blur-container"></div>
                <div className="see-more-container ">
                  <div className="profile-see-more">
                    To see more, create a PushPull card 🥳
                  <div className="profile-see-more-subtext">
                      Build more meaningful relationships and showcase your
                      authentic personality
                  </div>
                  </div>
                  <div
                    className="create-card"
                    onClick={() => {
                      window.location.assign("/login");
                    }}
                  >
                    Create your card
                </div>
                </div>
              </div>
            </div>
          )}
      </UserContext.Provider>
    </div>
  );
}

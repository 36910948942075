import React from "react";
import ProgressBar from "../ProgressBar";

export default function EmailSignatureCard(props) {
  const { values } = props;
  console.log("im in header card! here's the user handle: ", values.handle);

  const Next = async e => {
    e.preventDefault();
    console.log("button pressed");
    props.nextStep();
  };

  const Previous = async e => {
    e.preventDefault();

    props.previousStep();
  };

  return (
    <div>
      <div className="profile-helper-box-title collection collection2">
        How to get the most out of your PushPull Card in 3 simple steps!
      </div>
      <div className="profile-helper-box collection">
        <ProgressBar percent={values.percent} />
        <div style={{ clear: "both" }}></div>
        <div className="profile-helper-box-content">
          <div className="arrow-container">
            <button className="arrow arrow-left" onClick={Previous}></button>{" "}
          </div>
          <div className="arrow-container"></div>
          <div className="profile-helper-box-text">
            Step 3: Hyperlink your PushPull card in your email signature
            <br></br>
            <div style={{ display: "inline" }}>
              {" "}
              <a
                style={{ display: "inline" }}
                href={`https://www.wisestamp.com/blog/how-to-create-a-professional-gmail-signature-the-easy-step-by-step-guide/`}
              >
                Here's how!{" "}
              </a>
              <br />
              <div className="profile-helper-box-subtext">
                Your PushPull is viewable and shareable at:{" "}
                {values.handle ? (
                  <a href={`/user/${values.handle}`}>
                    pushpull.community/user/{values.handle}
                  </a>
                ) : (
                    ""
                  )}{" "}
              </div>
            </div>
          </div>
          <div className="profile-helper-box-image">
            <img
              class="signature-screenshot"
              src={require("../../images/signature.png")}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

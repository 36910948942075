import React from "react";
import { Link } from "react-router-dom";

export default function UserNotFound() {
  return (
    <div>
      <h1 style={{ marginTop: 250 }}>User not found :(</h1>
      <h3>But if you want to claim this handle...</h3>
      <div style={{ marginTop: 30 }}>
        <Link
          style={{
            backgroundColor: "#e2474b",
            borderRadius: 8,
            padding: "12px 32px",
            textAlign: "center",
            fontSize: 16,
            color: "white",
            fontWeight: "bold",
            fontFamily: "Raleway"
          }}
          onClick={() => {
            window.location.assign("/login");
          }}
        >
          Create your card
        </Link>
      </div>
    </div>
  );
}

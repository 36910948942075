import React, { useState, useContext, useEffect } from "react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Emoji } from "emoji-mart";
import ProgressBar from "../ProgressBar";
import { validatePersonalDetails } from "../../schemas/onboarding";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";

export default function FormPersonalDetails(props) {
  const { profiles } = useContext(AuthContext);
  const [pickerState, setPickerState] = useState(false);
  const [handleError, setHandleError] = useState("");
  const { values, sets } = props;

  useEffect(() => {
    const setLastOnboarding = async () => {
      if (!(Object.keys(profiles).length === 0)) {
        const ref = await firebase
          .firestore()
          .collection("profiles")
          .doc(profiles.document_key);
        ref.get().then(doc => {
          let profile = doc.data();
          if (!profile.hasOwnProperty("onboarding_1_personalDetails")) {
            let data = {
              last_onboarding_step: "onboarding_1_personalDetails"
            };
            ref.set(data, { merge: true });
          }
        });
      }
    };
    setTimeout(() => {
      setLastOnboarding();
    }, 100);
  }, [profiles]);

  const Next = async e => {
    e.preventDefault();
    try {
      const isValid = await validatePersonalDetails.validate(
        {
          Name: values.name,
          Job: values.job,
          Location: values.location,
          handle: values.handle
        },
        {
          abortEarly: false
        }
      );

      if (!isValid) {
        return;
      }
    } catch (err) {
      console.log(err.errors[0]);
      setHandleError(err.errors[0]);
      return;
    }

    console.log("check something", values.handle);
    // check handle isn't already in database
    const ref_handle = await firebase
      .firestore()
      .collection("userHandles")
      .doc(values.handle);

    ref_handle.get().then(async doc => {
      console.log("did we get here", doc);
      if (doc.exists) {
        let handleOwner = await doc.data();
        console.log("handle owner", handleOwner);

        if (handleOwner.user !== profiles.document_key) {
          setHandleError("User handle already exists.");
          throw "err";
        } else {
          const ref = await firebase
            .firestore()
            .collection("profiles")
            .doc(profiles.document_key);

          ref.get().then(doc => {
            let profile = doc.data();
            if (!profile.hasOwnProperty("onboarding_1_personalDetails")) {
              let data = {
                onboarding_1_personalDetails: new Date()
              };
              ref.set(data, { merge: true });
            }
          });

          props.nextStep();
        }
      } else {
        const ref = await firebase
          .firestore()
          .collection("profiles")
          .doc(profiles.document_key);

        ref.get().then(doc => {
          let profile = doc.data();
          if (!profile.hasOwnProperty("onboarding_1_personalDetails")) {
            let data = {
              onboarding_1_personalDetails: new Date()
            };
            ref.set(data, { merge: true });
          }
        });

        props.nextStep();
      }
    });
  };

  // const formatLinkedinUrl = (url) => {
  //   // let url = values.linkedin;
  //   if (!url.startsWith("http")) {
  //     url = "http://" + url
  //   }
  //   console.log("here's the url: ", url)
  //   sets.setLinkedin(url)
  //   // sets.setLinkedin(e.currentTarget.value);
  // }

  const onEmojiClick = emojiObject => {
    sets.setChosenEmoji(emojiObject);
    setPickerState(false);
  };

  /**
   * If tapping "Enter", submit form and continue to the next page of onboarding.
   * Function is only needed because emoji-mart widget overtakes the Enter key press.
   **/
  const checkAndHandleEnterKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      Next(e);
    }
  };

  return (
    <div>
      <div className="onboarding-progress-bar">
        <ProgressBar percent={values.percent} />
        <div style={{ clear: "both" }}></div>
      </div>
      <form onSubmit={Next}>
        <h4 className="onboarding-card-title">
          Let's get you set up as quickly as possible! 💪
        </h4>
        <label className="onboarding-label" for="name" style={{ fontSize: 15 }}>
          Name
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="name"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder="Jarett Cawsey"
          onChange={e => {
            sets.setName(e.currentTarget.value);
          }}
          value={values.name}
        />
        <br />
        <label
          className="onboarding-label"
          for="handle"
          style={{ fontSize: 15 }}
        >
          Choose your username
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="handle"
          type="text"
          autoFocus="autofocus"
          style={{ color: "#2f3a56" }}
          placeholder={"melinda-gates"}
          onChange={e => {
            sets.setHandle(e.currentTarget.value);
          }}
          value={values.handle}
        />
        <br />
        <label
          className="onboarding-label"
          for="emoji"
          style={{ fontSize: 15 }}
        >
          Choose an emoji that represents you
        </label>
        <br />
        <button
          class="emoji-picker"
          onClick={e => {
            e.preventDefault();
            setPickerState(!pickerState);
          }}
        >
          <Emoji emoji={values.chosenEmoji} set="messenger" size={30} />
        </button>
        {pickerState ? (
          <Picker
            className="nav"
            title="Pick your emoji..."
            set="messenger"
            style={{ position: "absolute", top: "20%", left: "5%" }}
            onSelect={onEmojiClick}
          />
        ) : null}

        <br />
        <label className="onboarding-label" for="job" style={{ fontSize: 15 }}>
          Job
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="job"
          type="text"
          placeholder="Senior UX Designer @ Pinterest"
          onChange={e => {
            sets.setJob(e.currentTarget.value);
          }}
          value={values.job}
        />
        <br />
        {/* <label
          className="onboarding-label"
          for="location"
          style={{ fontSize: 15 }}
        >
          Location
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="location"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder="San Francisco, CA"
          onChange={e => {
            sets.setLocation(e.currentTarget.value);
          }}
          value={values.location}
        /> */}
        <label
          className="onboarding-label"
          for="linkedin"
          style={{ fontSize: 15 }}
        >
          LinkedIn URL (optional)
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="linkedin"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder="Enter your linkedin URL"
          onChange={e => {
            sets.setLinkedin(e.currentTarget.value);
          }}
          value={values.linkedin}
        />
        <label
          className="onboarding-label"
          for="community"
          style={{ fontSize: 15 }}
        >
          Community Invite Code (optional)
        </label>
        <input
          onKeyPress={checkAndHandleEnterKeyPress}
          id="community"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder="Enter invite-only code"
          onChange={e => {
            sets.setCommunity(e.currentTarget.value);
          }}
          value={values.community}
        />
        {handleError.length > 0 ? (
          <div className="handle-error-text">{handleError}</div>
        ) : (
            <div />
          )}
        <div class="button-bar">
          <button
            onClick={Next}
            className="pushpull-btn right"
            type="submit"
            name="action"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

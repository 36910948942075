import * as yup from "yup";

export const handleNotLongEnough = "Handle required";

export const validHandleSchema = yup.object().shape({
  userHandle: yup
    .string()
    .min(1, handleNotLongEnough)
    .max(255)
    .required()
});

import React, { useState, useEffect, useContext } from "react";

export default function Sidebar(props) {
  const [activeTab, setActiveTab] = useState("activity");
  const membersClick = e => {
    e.preventDefault();
    setActiveTab("members");
    props.setTab("members");
  };

  const activityClick = e => {
    e.preventDefault();
    setActiveTab("activity");
    props.setTab("activity");
  };
  return (
    <div>
      <div style={{ marginTop: 79 }} className="home-page">
        <div class="sidebar">
          <div class="sidebar-pages" onClick={activityClick}>
            <a className={activeTab === "activity" ? "active" : null}>
              <img
                src={require("../../images/exchange.png")}
                style={{ marginRight: 10 }}
              />{" "}
              Activity
            </a>
          </div>
          <div class="sidebar-pages" onClick={membersClick}>
            <a className={activeTab === "members" ? "active" : null}>
              <img
                src={require("../../images/members.png")}
                style={{ marginRight: 10 }}
              />{" "}
              Members
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

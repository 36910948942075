import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import HomePage from "./pages/Profile/HomePage";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "./pages/Login/LoginPage";
import { AuthProvider } from "./components/auth/AuthProvider";
import PrivateRoute from "./components/auth/PrivateRoute";
import WelcomeRoute from "./components/auth/WelcomeRoute";
import CommunityRoute from "./components/auth/CommunityRoute";
import ExplorePage from "./pages/Explore/ExplorePage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import UserForm from "./components/onboarding/UserForm";
import UserNotFound from "./pages/UserNotFound/UserNotFound";
import Communities from "./pages/Communities/Communities";
import SlackPage from "./pages/Slack/SlackPage";
import LogRocket from "logrocket";
LogRocket.init("3iiaic/test-project");

// This is an example script - don't forget to change it!
LogRocket.identify("THE_USER_ID_IN_YOUR_APP", {
  name: "Alan Zhao",
  email: "alan@warmlycomma.com",

  // Add your own custom user variables here, ie:
  subscriptionType: "pro"
});

const Root = () => (
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        <WelcomeRoute exact={true} path="/" />
        <Route exact={true} path="/user-not-found" component={UserNotFound} />
        <Route exact={true} path="/communities" component={Communities} />
        <CommunityRoute
          exact={true}
          path="/communities/ondeck"
          component={Communities}
          community={"on_deck"}
        />
        <Route exact={true} path="/slack" component={SlackPage} />
        {/* <Route
          exact={true}
          path="/slack"
          render={() => {
            return (
              <Redirect
                exact={true}
                to={
                  "https://slack.com/oauth/v2/authorize?client_id=718420716803.934048228483&scope=app_mentions:read,calls:write,channels:read,chat:write,groups:write,im:history,im:write,incoming-webhook,mpim:write,users.profile:read,users:read,users:write,channels:history&user_scope=chat:write,im:history,channels:history"
                }
              />
            );
          }}
        /> */}

        <PrivateRoute exact={true} path="/onboarding" component={UserForm} />
        <Route exact={true} path="/privacy-policy" component={PrivacyPolicy} />
        {/* <PrivateRoute exact={true} path="/" component={HomePage} /> */}
        <PrivateRoute exact={true} path="/edit" component={HomePage} />
        <Route exact={true} path="/login" component={LoginPage} />
        <Route exact={true} path="/explore" component={ExplorePage} />

        <Route
          exact={true}
          path="/user/:userHandleParam"
          component={HomePage}
        />
      </Switch>
    </BrowserRouter>
  </AuthProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

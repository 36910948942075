import React, { useState, useEffect, useContext } from "react";
import EmailSignatureCard from "./EmailSignatureCard";
import SocialMediaCard from "./SocialMediaCard";
import FillOutProfileCard from "./FillOutProfileCard";
import QRCodeCard from "./QRCodeCard";
import "./HeaderCards.css";
import ExplorePageCard from "./ExplorePageCard";

export default function HeaderCards(props) {
  const [step, setStep] = useState(1);
  const [percent, setPercent] = useState("");

  console.log("handle user hanle", props.userHandle);

  useEffect(() => {
    if (step === numCards) {
      setPercent(100);
    } else {
      setPercent((step * 100) / (numCards - 1));
    }
  }, [step]);

  //used for progressbar (+1 from total cards to show some progress from first card)
  const numCards = 4;

  // proceed to next step
  const nextStep = () => {
    setStep(step + 1);
    setPercent((step * 100) / (numCards - 1));
  };

  // proceed to previous step
  const previousStep = () => {
    setStep(step - 1);
  };

  // handle field change
  const handleChange = input => e => {
    console.log(input, e);
  };
  const values = {
    percent,
    handle: props.userHandle
  };
  const sets = {
    setPercent
  };

  var headerCard;

  switch (step) {
    case 1:
      headerCard = (
        <ExplorePageCard
          nextStep={nextStep}
          profiles={props.profiles}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case 2:
      headerCard = (
        <FillOutProfileCard
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;

    case 3:
      headerCard = (
        <EmailSignatureCard
          nextStep={nextStep}
          previousStep={previousStep}
          handleChange={handleChange}
          values={values}
          sets={sets}
        />
      );
      break;
  }

  return <div> {headerCard}</div>;
}

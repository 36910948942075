import React, { useState, useContext, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { validateTidbits } from "../../schemas/onboarding";
import firebase from "../../config/constants";
import { AuthContext } from "../auth/AuthProvider";
import { whitelisted_communities } from "./config";

export default function Tidbit(props) {
  const { profiles } = useContext(AuthContext);
  const [handleError, setHandleError] = useState("");

  useEffect(() => {
    const setLastOnboarding = async () => {
      if (!(Object.keys(profiles).length === 0)) {
        const ref = await firebase
          .firestore()
          .collection("profiles")
          .doc(profiles.document_key);
        ref.get().then(doc => {
          let profile = doc.data();
          if (!profile.hasOwnProperty("onboarding_5_tidBits")) {
            let data = {
              last_onboarding_step: "onboarding_5_tidBits"
            };
            ref.set(data, { merge: true });
          }
        });
      }
    };
    setTimeout(() => {
      setLastOnboarding();
    }, 100);
  }, [profiles]);

  const Previous = async e => {
    e.preventDefault();
    props.previousStep();
  };
  const Next = async e => {
    e.preventDefault();
    try {
      const isValid = await validateTidbits.validate(
        {
          interestingContent: values.interestingContent
        },
        {
          abortEarly: false
        }
      );

      if (!isValid) {
        return;
      }
    } catch (err) {
      console.log(err.errors[0]);
      setHandleError(err.errors[0]);
      return;
    }

    const ref = await firebase
      .firestore()
      .collection("profiles")
      .doc(profiles.document_key);

    ref.get().then(doc => {
      let profile = doc.data();
      if (!profile.hasOwnProperty("onboarding_5_tidBits")) {
        let data = {
          onboarding_5_tidBits: new Date()
        };
        ref.set(data, { merge: true });
      }
    });

    props.nextStep();
  };
  const { values, sets } = props;
  return (
    <div>
      <div className="onboarding-progress-bar">
        <ProgressBar percent={values.percent} />
        {values.community in whitelisted_communities ? (
          < div >
            <img
              className="onboarding-community-logo"
              src={whitelisted_communities[values.community].logo}
            />
          </ div>
        ) : null}
        <div style={{ clear: "both" }}></div>
      </div>
      <form>
        <h4 className="onboarding-card-title">
          Tidbits: One little-known fact about me
          {values.community in whitelisted_communities ? (
            <div className="onboarding-pushpull-privacy">
              <i className="fa fa-lock"></i> Only visible to{" "}
              {whitelisted_communities[values.community].name}
            </div>
          ) : null}
        </h4>
        <textarea
          id="interestingContent"
          autoFocus="autofocus"
          type="text"
          style={{ color: "#2f3a56" }}
          placeholder="My past lives: documentary film maker, chef, and cake decorator."
          onChange={e => {
            sets.setInterestingContent(e.currentTarget.value);
          }}
          defaultValue={values.interestingContent}
        />
        <div className="handle-error-text">{handleError}</div>

        <div class="button-bar">
          <button
            onClick={Next}
            className="pushpull-btn right"
            type="submit"
            name="action"
          >
            🥳Finish
          </button>
          <button
            onClick={Previous}
            className="pushpull-btn secondary left"
            type="submit"
            name="action"
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

import * as yup from "yup";

export const validatePersonalDetails = yup.object().shape({
  Name: yup.string().required("Name required."),
  Job: yup.string().required("Job required"),
  // Location: yup.string().required("Location required."),
  handle: yup.string().required("User handle required")
});

export const validateProfessionalPush = yup.object().shape({
  pushProfessionalTitle: yup
    .string()
    .required("Professional favor title required."),
  pushProfessionalContent: yup
    .string()
    .required("Professional favor details required.")
});

export const validatePersonalPush = yup.object().shape({
  pushPersonalTitle: yup.string().required("Personal favor title required."),
  pushPersonalContent: yup.string().required("Personal favor details required.")
});

export const validatePull = yup.object().shape({
  pullTitle: yup
    .string()
    .required("Title of what you would like help with required."),
  pullContent: yup
    .string()
    .required("Details of what you would like help with required.")
});

export const validateTidbits = yup.object().shape({
  interestingContent: yup.string().required("Interesting fact required :).")
});

import React, { useState, useEffect, useContext } from "react";
import "./HomePage.css";
import firebase, { firebaseAuth } from "../../config/constants";
import ProfilePage from "./ProfilePage";
import NavBar from "../../components/navbar/NavBar";
import { AuthContext } from "../../components/auth/AuthProvider";
import UserNotFound from "../UserNotFound/UserNotFound";
export const UserContext = React.createContext();

export default function HomePage(props) {
  let { profiles, setProfiles } = useContext(AuthContext);
  let [loading, setLoading] = useState(false);
  let [noUserFlag, setNoUserFlag] = useState(false);
  let userHandleParam = props.match.params.userHandleParam || "";

  let editMode = true;
  if (userHandleParam !== "") {
    editMode = false;
  }

  console.log("loading", loading);

  useEffect(() => {
    if (userHandleParam !== "") {
      firebase
        .firestore()
        .collection("profiles")
        .where("userHandle", "==", userHandleParam.toLowerCase())
        .get()
        .then(snapshot => {
          if (snapshot.length !== 0) {
            setNoUserFlag(true);
          }
          setLoading(false);
          snapshot.docs.forEach(doc => {
            setNoUserFlag(false);
            let profileData = doc.data();
            console.log("check if user is here", profileData);
            setProfiles(profileData);
          });
        });
    }
  }, []);

  return loading ? null : (
    <div>
      <UserContext.Provider
        value={{
          userHandleParam: userHandleParam,
          editMode: editMode,
          profiles: profiles
        }}
      >
        <NavBar />
        <div className="home-page ">
          {noUserFlag ? (
            <UserNotFound />
          ) : (
            <ProfilePage userHandleParam={userHandleParam} />
          )}
        </div>
      </UserContext.Provider>
    </div>
  );
}

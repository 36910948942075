import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const WelcomeRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={routeProps => {
        return !!currentUser ? (
          <Redirect exact={true} to={"/edit"} />
        ) : (
          //   <Redirect exact={true} to={"/main"} />
          <Route
            exact={true}
            path="/"
            render={() => {
              window.location.href = "/home.html";
            }}
          />
        );
      }}
    />
  );
};

export default WelcomeRoute;

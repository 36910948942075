import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../pages/Profile/HomePage";

import "./Personal.css";
import "./AddNote.css";

export default function AddNote(props) {
  const { notes, addNote, deleteNote, currentCardId } = props;
  const [content, setContent] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [showInputForm, setShowInputForm] = useState(false);

  let { editMode } = useContext(UserContext);

  const handleTitleChange = e => {
    setContentTitle(e.target.value);
  };
  const handleContentChange = e => {
    setContent(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    addNote({ title: contentTitle, content }, notes);

    setShowInputForm(prev => {
      return !prev;
    });
    setContentTitle("");
    setContent("");
  };

  const handleClick = () => {
    setShowInputForm(prev => {
      return !prev;
    });
  };

  return showInputForm ? (
    <div>
      <form style={{ width: "100%" }}>
        <div className="note-card">
          <input
            type="text"
            style={{ marginTop: 10 }}
            placeholder="Give it a title"
            value={contentTitle}
            onChange={handleTitleChange}
          />
          <textarea
            type="text"
            placeholder="Add some details 😁"
            value={content}
            onChange={handleContentChange}
          />
          <div className="button-bar">
            <button
              onClick={handleSubmit}
              className="pushpull-btn right"
              type="submit"
              name="action"
            >
              Save
            </button>
            <button
              className="pushpull-btn left secondary"
              onClick={e => {
                e.preventDefault();
                deleteNote(currentCardId, notes);
                setShowInputForm(prev => {
                  return !prev;
                });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  ) : (
      <div className="right-align" style={{ padding: 0, position: "relative", top: "-20px" }}>
        <div style={{ flex: 1, marginTop: "0px" }}>
          {editMode ? (
            <div class="topright">
              <img
                onClick={handleClick}
                style={{ width: 20, cursor: "pointer" }}
                src={require("../../images/plus.png")}
              />
            </div>
          ) : (
              <div></div>
            )}
        </div>
      </div>
    );
}
